import { defineComponent, ref, onMounted, watch, computed} from 'vue'
import {axiosInstance, freshNoToastLoggedInAxiosInstance} from 'src/boot/axios'
import * as ilapi from 'src/composables/InleagueApiV1'
import Portlet from 'src/components/Portlets/Portlet.vue'
import { DivisionStatsPortlet, Guid, Season } from 'src/interfaces/InleagueApiV1'
import { System } from 'src/store/System'
import { Client } from 'src/store/Client'
import { FormKit } from '@formkit/vue'
import { DeepConst, UiOption, copyViaJsonRoundTrip, gatherByKey_manyPerKey, vOptT, vReqT } from 'src/helpers/utils'

/**
 * This is a value as provided and expected by the backend to represent the "all divisions" case
 */
const DIVID_ALL = "All"

export default defineComponent({
  name: 'DivisionStatistics',
  props: {
    showContent: vOptT<boolean>(),
    divisionStats: vReqT<DivisionStatsPortlet>(),
  },
  setup(props) {
    const ready = ref(false);

    // copy this in, and then we update it locally from here on out
    const localDivisionStats = ref(copyViaJsonRoundTrip(props.divisionStats))

    const selectedCompetitionUID = ref<Guid>(localDivisionStats.value.competitionUID)
    const competitionOptions = ref<UiOption[]>([])

    const selectedSeasonUID = ref<Guid>(localDivisionStats.value.seasonUID)
    const seasonOptions = ref<UiOption[]>([])

    const selectedDivID = ref<Guid>(localDivisionStats.value.division.divID)
    const divisionOptions = ref<UiOption[]>([])

    const show = ref(props.showContent)

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const doToggleExpand = (expand: boolean) : void => {
      show.value = expand
    }

    const maybeFixupSelectedDivID = () : void => {
      if (!divisionOptions.value.find(v => v.value === selectedDivID.value)) {
        // Options list no longer contains the selected value.
        // Set it DIVID_ALL, which should always be a valid value
        selectedDivID.value = DIVID_ALL
      }
    }

    onMounted(async () => {
      competitionOptions.value = createCompOptions(localDivisionStats.value.allCompetitions)
      seasonOptions.value = createSeasonOptions(await Client.loadSeasons(freshNoToastLoggedInAxiosInstance()))
      divisionOptions.value = createDivOptions({seasonUID: selectedSeasonUID.value, competitionUID: selectedCompetitionUID.value}, localDivisionStats.value);
      maybeFixupSelectedDivID()
      ready.value = true;
    })

    /**
     * This does not need to be immediate because we have an initial value for `localDivisionStats` provided onMount from parent component.
     */
    watch(
      [selectedCompetitionUID, selectedSeasonUID, selectedDivID],
      async () => {
        localDivisionStats.value = await ilapi.getDivisionStats(axiosInstance, {
          competitionUID: selectedCompetitionUID.value || undefined,
          seasonUID: selectedSeasonUID.value || undefined,
          divID: selectedDivID.value,
        })

        // well, actually we don't need to do this if divID was the only thing that changed, but it doesn't really hurt
        divisionOptions.value = createDivOptions({seasonUID: selectedSeasonUID.value, competitionUID: selectedCompetitionUID.value}, localDivisionStats.value);
        maybeFixupSelectedDivID()
      },
      { immediate: false }
    )

    return () => {
      if (!ready.value) {
        return null
      }
      if (isMobile.value && !show.value) {
        return null
      }

      return (
        <Portlet label="Division Statistics" icon={localDivisionStats.value.fontAwesome || "book"} prefix="fas" onExpand={doToggleExpand}>
          <div>
            <div class="border-b border-gray-200 min-w-full px-6 pb-2" style="--fk-margin-outer:none;">
              <div class="my-2">
                <div class="text-sm font-medium">Program</div>
                <FormKit
                  type="select"
                  options={competitionOptions.value}
                  v-model={selectedCompetitionUID.value}
                  {...{placeholder:"Select a Program"}} data-cy="competition"
                />
              </div>
              <div class="my-2">
                <div class="text-sm font-medium">Season</div>
                <FormKit
                  type="select"
                  options={seasonOptions.value}
                  v-model={selectedSeasonUID.value}
                  {...{placeholder:"Select a Season (optional)"}} data-cy="season"
                />
              </div>
              <div class="my-2">
                <div class="text-sm font-medium">Division</div>
                <FormKit
                  type="select"
                  options={divisionOptions.value} v-model={selectedDivID.value}
                  {...{placeholder:"Select a Division"}} data-cy="division"
                />
              </div>
            </div>
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Registered Players</td>
                          <td class="px-6 py-4 whitespace-nowrap">{ localDivisionStats.value.statistics.registeredPlayers }</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Waitlisted Players</td>
                          <td class="px-6 py-4 whitespace-nowrap">{ localDivisionStats.value.statistics.waitlistedPlayers }</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Dropped Players</td>
                          <td class="px-6 py-4 whitespace-nowrap">{ localDivisionStats.value.statistics.droppedPlayers }</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Head Coach Volunteers</td>
                          <td class="px-6 py-4 whitespace-nowrap">{ localDivisionStats.value.statistics.headCoachVols }</td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Assistant Coach Volunteers</td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            { localDivisionStats.value.statistics.asstCoachVols }
                            {" "}
                            ({localDivisionStats.value.statistics.asstCoachOnlyVols} Asst. Only)
                          </td>
                        </tr>
                        <tr>
                          <td class="px-6 py-4 whitespace-nowrap">Referee Volunteers</td>
                          <td class="px-6 py-4 whitespace-nowrap">{ localDivisionStats.value.statistics.refVols}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portlet>
      )
    }
  },
})

const createCompOptions = (competitions: {competitionUID: string, competition: string}[]) : UiOption[] => {
  return competitions
    .map(comp => ({label: comp.competition, value: comp.competitionUID}))
}

const createSeasonOptions = (seasons: DeepConst<Season[]>) : UiOption[] => {
  return seasons
    .map(season => ({label: season.seasonName, value: season.seasonUID}))
}

const createDivOptions = (
  currentSelections: {competitionUID: "" | Guid, seasonUID: "" | Guid},
  {allDivisions, compSeasonDivInfo}: DivisionStatsPortlet,
) : UiOption[] => {
  const compSeasonDivsByDivID = gatherByKey_manyPerKey(compSeasonDivInfo, (div) => div.divID)

  return allDivisions
    .filter(div => {
      const isAllPlaceholder = div.divID === DIVID_ALL;
      if (isAllPlaceholder) {
        return true;
      }
      else {
        const allComps = !currentSelections.competitionUID
        const allSeasons = !currentSelections.seasonUID

        const compSeasonDivsForDiv = compSeasonDivsByDivID.get(div.divID)
        if (!compSeasonDivsForDiv) {
          // No compSeasonDiv for this div in the DB, so we can't even ask if it's active.
          // true seems reasonable here.
          return true
        }

        return !!compSeasonDivsForDiv.find(v => {
          const matchComp = allComps || currentSelections.competitionUID === v.competitionUID
          const matchSeason = allSeasons || currentSelections.seasonUID === v.seasonUID
          return matchComp && matchSeason && v.isActive
        })
      }
    })
    .map(div => ({label: div.divisionName, value: div.divID}));
}
