import { dollarFormat, exhaustiveCaseGuard, FK_nodeRef, FormKitValidationRule, nextOpaqueVueKey, parseFloatOr, parseIntOr, TailwindBreakpoint, UiOptions, unreachable, useWindowSize, vReqT } from "src/helpers/utils";
import { computed, defineComponent, ref } from "vue";
import { InvoiceTemplate, InvoiceTemplatePaymentMethod, InvoiceTemplatePaymentMethodIntervalUnit } from "./InvoiceTemplates.io";
import { dayjsOr, DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate";
import { Guid, Floatlike, DateTimelike, Integerlike, Invoice } from "src/interfaces/InleagueApiV1";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { Btn2 } from "../UserInterface/Btn2";
import { X } from "../SVGs";
import { QuillWrapper3 } from "../UserInterface/QuillWrapper3";
import { FkDynamicValidation } from "src/helpers/FkUtils";

export const InvoiceTemplatePaymentMethodFormElem = defineComponent({
  props: {
    invoiceAmount: vReqT<number | "unknown">(),
    paymentMethod: vReqT<InvoiceTemplatePaymentMethodForm_New>(),
  },
  setup(props) {
    const windowSize = useWindowSize()
    const displayMode = computed(() => windowSize.width < TailwindBreakpoint.lg ? "small" : "big")

    const amounts = computed(() => {
      const totalOccurrences = parseIntOr(props.paymentMethod.totalOccurrences, null)
      const amountPerOccurrence = parseFloatOr(props.paymentMethod.amount, null)
      const trialOccurrences = parseIntOr(props.paymentMethod.trialOccurrences, null)
      const amountPerTrialOccurrence = parseFloatOr(props.paymentMethod.trialAmount, null)

      const nonTrialAmount = typeof totalOccurrences === "number" && typeof amountPerOccurrence === "number" ? (totalOccurrences * amountPerOccurrence) : null;
      const trialAmount = typeof trialOccurrences === "number" && typeof amountPerTrialOccurrence === "number" ? (trialOccurrences * amountPerTrialOccurrence) : null

      const uiTotal = nonTrialAmount !== null || trialAmount !== null
        ? "$" + dollarFormat((nonTrialAmount ?? 0) + (trialAmount ?? 0))
        : ""

      return {
        nonTrialAmount,
        trialAmount,
        uiTotal
      }
    })

    const ConsistentAmountValidation = defineComponent(() => {
      const amountValidation = computed(() : "" | "ok" => {
        if (props.invoiceAmount === "unknown") {
          return ""
        }

        const nonTrialAmount = amounts.value.nonTrialAmount ?? 0
        const trialAmount = amounts.value.trialAmount ?? 0

        if ((nonTrialAmount + trialAmount) === props.invoiceAmount) {
          return "ok"
        }
        else {
          return ""
        }
      })

      const nodeRef = FK_nodeRef()

      return () => props.invoiceAmount === "unknown"
        ? null
        : <div>
          <FormKit
            type="hidden"
            v-model={amountValidation.value}
            // required validation --- we send in an empty-or-not-empty value in the v-model
            // based on if we have determined other fields to be valid
            validation="required"
            validationMessages={{required: `Payment method amount must match invoice amount of $${dollarFormat(props.invoiceAmount)}`}}
            validationVisibility="dirty"
            ref={nodeRef}
          />
          <FormKitMessages node={nodeRef.value?.node}/>
        </div>
    })

    const AtLeast2PaymentsValidator = FkDynamicValidation(() => {
      const initialPayments = parseIntOr(props.paymentMethod.trialOccurrences, undefined)
      const regularPayments = parseIntOr(props.paymentMethod.totalOccurrences, undefined)
      if (initialPayments === undefined && regularPayments === undefined) {
        return {status: "unknown"}
      }
      else if (initialPayments === undefined && regularPayments !== undefined) {
        return regularPayments >= 2
          ? {status: "valid"}
          : {status: "invalid", msg: "Total payment occurrences must be greater than 1."}
      }
      else if (initialPayments !== undefined && regularPayments === undefined) {
        return {status: "unknown"}
      }
      else if (initialPayments !== undefined && regularPayments !== undefined) {
        return (regularPayments + initialPayments) >= 2
          ? {status: "valid"}
          : {status: "invalid", msg: "Total payment occurrences must be greater than 1."}
      }
      else {
        unreachable()
      }
    })

    return () => {
      const intervalUnitOptions = [
        {label: "Days", value: "days" satisfies InvoiceTemplatePaymentMethodIntervalUnit},
        {label: "Months", value: "months" satisfies InvoiceTemplatePaymentMethodIntervalUnit},
      ];

      return (
        <div style={{
          display: "grid",
          gridTemplateColumns: displayMode.value === "small" ? "1fr" : "max-content 1fr",
          gap: displayMode.value === "small" ? ".3em .5em" : ".3em .5em"
        }}>

        <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">
              Regular payments are required.
            </div>
            <div>

            </div>
        </div>
        <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">
              Initial payments are optional.
            </div>
            <div>

            </div>
        </div>
          <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">
              Payments occur every
              <RequiredStar/>
            </div>
            <div class="flex items-start gap-2 ">
              <FormKit type="number" v-model={props.paymentMethod.intervalLength} data-test="intervalLength" validation={[["required"], ["min", 1]]} validationLabel="Value"/>
              <FormKit type="select" options={intervalUnitOptions} v-model={props.paymentMethod.intervalUnit} data-test="intervalUnit" validation={[["required"]]} validationLabel="Value"/>
            </div>
          </div>

          <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">Number of initial payments</div>
            <div class="flex items-start gap-2 ">
              <FormKit type="number" v-model={props.paymentMethod.trialOccurrences} data-test="trialOccurrences" validation={[["min", 0]]} validationLabel="Value"/>
            </div>
          </div>

          <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">Initial payment amount (each)</div>
            <div class="flex items-start gap-2 ">
              <FormKit type="number" step=".01" v-model={props.paymentMethod.trialAmount} data-test="trialAmount" validation={[["min", 0]]} validationLabel="Value"/>
            </div>
          </div>

          <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">
              Number of regular payments
              <RequiredStar/>
            </div>
            <div class="flex items-start gap-2 ">
              <FormKit type="number" v-model={props.paymentMethod.totalOccurrences} data-test="totalOccurrences" validation={[["required"], ["min", 1]]} validationLabel="Value"/>
            </div>
          </div>

          <div style="display:contents;" data-note-grid-rowlike>
            <div class="pr-1">
              Regular payment amount (each)
              <RequiredStar/>
            </div>
            <div class="flex items-start gap-2 ">
              <FormKit type="number" step=".01" v-model={props.paymentMethod.amount} data-test="amount" validation={[["required"]]} validationLabel="Value"/>
            </div>
          </div>

          <div style="grid-column:-1/1;" data-note-gride-rowlike>
            {amounts.value.uiTotal
              ? <div class="text-sm">Effective total: {amounts.value.uiTotal}</div>
              : <div class="text-sm">&nbsp;</div> // still take up space, so it's not jarring when it renders/un-renders
            }
            <ConsistentAmountValidation/>
            <AtLeast2PaymentsValidator/>
          </div>
        </div>
      )
    }
  }
})


export type InvoiceTemplateForm =
  | InvoiceTemplateForm_New
  | InvoiceTemplateForm_Edit

export type InvoiceTemplatePaymentMethodForm =
  & {__vueKey: string}
  & (
    | InvoiceTemplatePaymentMethodForm_New
    | InvoiceTemplatePaymentMethodForm_Existing
  )

type InvoiceTemplateForm_Common = {
  allowedPaymentMethods: ("card" | "us_bank_account")[],
	invoiceDesc: string,
	invoiceLabel: string,
	contactEmail: string,
	seasonUID: Guid,
	paymentGatewayID: Guid,
	amount: "" | Floatlike,
	archived: boolean,
	emailEveryReceipt: boolean,
	fixedDueDate: "" | DateTimelike,
  paymentMethods: InvoiceTemplatePaymentMethodForm[]
}

export type InvoiceTemplateForm_New = {
  mode: "new",
} & InvoiceTemplateForm_Common

export type InvoiceTemplateForm_Edit = {
  mode: "edit",
  original: InvoiceTemplate,
} & InvoiceTemplateForm_Common

export type InvoiceTemplatePaymentMethodForm_New = {
  type: "new",
  intervalLength: "" | Integerlike,
  intervalUnit: "days" | "months",
  trialAmount: "" | Floatlike,
  trialOccurrences: "" | Integerlike,
  /**
   * this is really "non-trial occurences"
   */
  totalOccurrences: "" | Integerlike,
  /**
   * this is really "non-trial amount"
   */
  amount: "" | Floatlike,
}

export type InvoiceTemplatePaymentMethodForm_Existing = {
  type: "existing",
  markedForDelete: boolean,
  object: InvoiceTemplatePaymentMethod
}

export function freshInvoiceTemplateForm(args:
  | {mode: "new", initialSeasonUID: Guid, initialClientGatewayID: Guid}
  | {mode: "edit", original: InvoiceTemplate}
) : InvoiceTemplateForm {
  if (args.mode === "new") {
    return {
      mode: "new",
      allowedPaymentMethods: ["card"],
      invoiceDesc: "",
      invoiceLabel: "",
      contactEmail: "",
      seasonUID: args.initialSeasonUID,
      paymentGatewayID: args.initialClientGatewayID,
      amount: "",
      archived: false,
      emailEveryReceipt: false,
      fixedDueDate: "",
      paymentMethods: [],
    }
  }
  else {
    return {
      mode: "edit",
      allowedPaymentMethods: args.original.allowedPaymentMethods,
      original: args.original,
      invoiceDesc: args.original.invoiceDesc,
      invoiceLabel: args.original.invoiceLabel,
      contactEmail: args.original.contactEmail,
      seasonUID: args.original.season.seasonUID,
      paymentGatewayID: args.original.paymentGatewayID,
      amount: args.original.amount,
      archived: !!args.original.archived,
      emailEveryReceipt: !!args.original.emailEveryReceipt,
      fixedDueDate: dayjsOr(args.original.fixedDueDate)?.format(DAYJS_FORMAT_HTML_DATE) ?? "",
      paymentMethods: args.original.paymentMethods.map(pm => freshInvoiceTemplatePaymentMethodForm({mode: "existing", obj: pm}))
    }
  }
}

export function freshInvoiceTemplatePaymentMethodForm(args: {mode: "new"} | {mode: "existing", obj: InvoiceTemplatePaymentMethod}) : InvoiceTemplatePaymentMethodForm {
  if (args.mode === "new") {
    return {
      __vueKey: nextOpaqueVueKey(),
      type: "new",
      intervalLength: "",
      intervalUnit: "days",
      trialAmount: "",
      trialOccurrences: "",
      totalOccurrences: "",
      amount: "",
    }
  }
  else {
    return {
      __vueKey: nextOpaqueVueKey(),
      type: "existing",
      markedForDelete: false,
      object: args.obj,
    }
  }
}

export const RequiredStar = defineComponent(() => () => <span class="inline-block text-xs text-red-700 relative" style="transform: translateX(2px) translateY(-4px);">*</span>)

export const InvoiceTemplateEditorFormElement = defineComponent({
  props: {
    form: vReqT<InvoiceTemplateForm>(),
    seasonOptions: vReqT<UiOptions>(),
    paymentGatewayOptions: vReqT<UiOptions>(),
  },
  emits: {
    pushTentativePaymentMethod: () => true,
    dropTentativePaymentMethod: (pm: InvoiceTemplatePaymentMethodForm) => true,
  },
  setup(props, ctx) {
    const sepClasses = "my-2"
    const Separator = defineComponent(() => () => <div style="grid-column:-1/1;"/>)

    // this is to make the quill html input participate in the formkit validation
    const fk_invoiceDescValidatableSource = (() => {
      const div = document.createElement("div")
      return {
        get value() {
          div.innerHTML = props.form.invoiceDesc
          return div.innerText
        },
        set value(v: any) {/*no-op*/}
      }
    })();
    const invoiceDescNodeRef = FK_nodeRef()

    return () => {
      return  <div
        style="display: grid; grid-template-columns: 25% var(--fk-max-width-input);"
      >
        <div class="mb-2" style="grid-column:-1/1;">
          <RuleFlankedHeading ratio={[1.5,9]}>
            <div class="px-2">Invoice</div>
          </RuleFlankedHeading>
        </div>
        <div style="display:contents;" data-note-grid-rowlike>
          <div>
            Invoice Short Label
            <RequiredStar/>
          </div>
          <FormKit
            type="text"
            v-model={props.form.invoiceLabel}
            data-test="invoiceLabel"
            validation={[["required"]]}
            validationLabel="Invoice label"
          />
        </div>

        <Separator class={sepClasses}/>

        <div style="grid-column:-1/1; width: calc(2 * var(--fk-max-width-input));" data-note-grid-rowlike>
          <div>
            <div>
              Detailed Description (Sent to Invited Families)
              <RequiredStar/>
            </div>
            <QuillWrapper3 v-model={props.form.invoiceDesc} data-test="invoiceDesc"/>
            <FormKit
              type="hidden"
              v-model={fk_invoiceDescValidatableSource.value}
              validation={[["required"]]}
              validationLabel="Invoice Description"
              ref={invoiceDescNodeRef}
            />
            <FormKitMessages node={invoiceDescNodeRef.value?.node}/>
          </div>
        </div>

        <div style="display:contents;" data-note-grid-rowlike>
          <div class="mt-4">
            Season
            <RequiredStar/>
          </div>
          <div class="mt-4">
            <FormKit
              type="select"
              disabled={props.seasonOptions.disabled}
              options={props.seasonOptions.options}
              v-model={props.form.seasonUID}
              data-test="seasonUID"
            />
          </div>
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>
            Invoice Amount
            <RequiredStar/>
          </div>
          <div>
            <FormKit
              type="number"
              step=".01"
              validation={[["required"], ["min", 0], ["max", 999_999]]}
              validationLabel="Amount"
              v-model={props.form.amount}
              data-test="amount"
            />
            {props.form.mode === "edit" && parseFloatOr(props.form.amount, null) !== parseFloatOr(props.form.original.amount, null)
              ? <div class="mt-1 text-xs">Changing the amount will require that you delete any existing payment methods, as they will reflect a total payment of the 'old' value.</div>
              : null}
          </div>
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>
            Payment Account
            <RequiredStar/>
          </div>
          <FormKit
            type="select"
            disabled={props.paymentGatewayOptions.disabled}
            options={props.paymentGatewayOptions.options} v-model={props.form.paymentGatewayID}
            validation={[["required"]]}
            validationLabel="Account"
            data-test="paymentGatewayID"
          />
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>Due Date</div>
          <FormKit
            type="date"
            v-model={props.form.fixedDueDate}
            data-test="fixedDueDate"
          />
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>
            Contact Email
            <RequiredStar/>
          </div>
          <FormKit
            type="email"
            v-model={props.form.contactEmail}
            data-test="contactEmail"
            validation={[["required"]]}
            validationLabel="Email"
          />
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>CC Contact on all reciepts</div>
          <label class="flex items-start gap-2">
            <div style="margin-top:4px">
              <FormKit
                type="checkbox"
                v-model={props.form.emailEveryReceipt}
                data-test="emailEveryReceipt"
              />
            </div>
            <span class="text-sm">When checked, the above email will receive a copy of all transactions.</span>
          </label>
        </div>

        <Separator class={sepClasses}/>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>Archive & Deactivate</div>
          <label class="flex items-start gap-2">
            <div style="margin-top:4px">
              <FormKit
                type="checkbox"
                v-model={props.form.archived}
                data-test="archived"
              />
            </div>
            <span class="text-sm">When checked, this invoice cannot be viewed or paid by invited parties.</span>
          </label>
        </div>

        <div style="display:contents;" data-note-grid-rowlike>
          <div>Allowed payment methods:</div>
          <div style="margin-top:4px; --fk-border: none; --fk-padding-fieldset:none;">
            <div data-test="allowedPaymentMethods">
              <FormKit
                type="checkbox"
                v-model={props.form.allowedPaymentMethods}
                options={[
                  {label: "Credit Card", value: "card"},
                  {label: "ACH (Bank Transfer)", value: "us_bank_account"}
                ]}
                validation={[["required"]]}
                validationLabel="Payment method type"
              />
            </div>
          </div>
        </div>

        <Separator class={[...sepClasses, "mt-2 mb-2"]}/>

        <div style="grid-column:-1/1;" data-note-grid-rowlike>
          <RuleFlankedHeading ratio={[1.5,9]}>
            <div class="px-2">Payment Schedules</div>
          </RuleFlankedHeading>
          <div class="my-3">
            <InvoicePaymentMethodCard offerDelete={false}>
              {{
                title: () => <div>Schedule 1</div>,
                body: () => <div class="p-2">
                  {(() => {
                    const amount = parseFloatOr(props.form.amount, null)
                    return amount === null
                      ? <div>Default 'pay all at once' method &ndash; <i>no amount specified in form</i></div>
                      : <div>This invoice may be paid in full (${amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}) in a single payment.</div>
                  })()}
                </div>
              }}
            </InvoicePaymentMethodCard>
          </div>
          {props.form.paymentMethods.length === 0
            ? null
            : props.form.paymentMethods.map((pm, i) => {
                // add 1 to accomodate the always-present default payment method, and add 1 more to adjust from 0-indexed to 1-indexed
                const indexUiValue = i + 2
                return <div class="my-3" key={pm.__vueKey} data-test={`paymentMethod/idx=${i}`}>
                  {pm.type === "new"
                    ? <InvoicePaymentMethodCard offerDelete={pm.type === "new"} onDelete={() => ctx.emit("dropTentativePaymentMethod", pm)}>
                      {{
                        title: () => <div>Schedule {indexUiValue}</div>,
                        body: () => <InvoiceTemplatePaymentMethodFormElem class="p-2" invoiceAmount={parseFloatOr(props.form.amount, "unknown")} paymentMethod={pm}/>
                      }}
                    </InvoicePaymentMethodCard>
                    : pm.type === "existing"
                    ? <InvoicePaymentMethodCard offerDelete={true} onDelete={() => {pm.markedForDelete = !pm.markedForDelete}}>
                        {{
                          title: () => <div>
                            Schedule {indexUiValue}
                            {pm.markedForDelete ? " (to be deleted)" : ""}
                          </div>,
                          body: () => <div class="p-2 relative">
                            <div>{paymentMethodDescription(pm.object)}</div>
                            {pm.markedForDelete
                              ? <div class="absolute top-0 left-0 rounded-b-md bg-black opacity-50 w-full h-full"/>
                              : null
                            }
                          </div>
                        }}
                    </InvoicePaymentMethodCard>
                    : exhaustiveCaseGuard(pm)
                  }
                </div>
              })
          }
          <div class="flex justify-end">
            <Btn2 class="mt-2 px-2 py-1" data-test="addPaymentMethod-button" onClick={() => ctx.emit("pushTentativePaymentMethod")}>Add Payment Method</Btn2>
          </div>
        </div>

        <Separator class={[...sepClasses, "my-4", "border-b"]}/>
      </div>
    }
  }
})

/**
 * produce a title text like "-------------- some title --------------------------------"
 * where the text is "flanked" by rules (lines)
 */
const RuleFlankedHeading = defineComponent({
  props: {
    ratio: vReqT<[number, number]>(),
  },
  setup(props, ctx) {
    return () => {
      return <div class="flex">
        <div class="relative" style={`flex-grow:${props.ratio[0]}`}><div class="absolute border-b h-3/6 w-full"/></div>
        <div class="px-2">{ctx.slots.default?.()}</div>
        <div class="relative" style={`flex-grow:${props.ratio[1]}`}><div class="absolute border-b h-3/6 w-full"/></div>
      </div>
    }
  }
})

const InvoicePaymentMethodCard = defineComponent({
  props: {
    offerDelete: vReqT<boolean>(),
  },
  emits: {
    delete: () => true,
  },
  setup(props, ctx) {
    return () => {
      return <div class={"border rounded-md"}>
        <div class="rounded-t-md px-2 bg-green-800 text-sm text-white flex items-center">
          {ctx.slots.title?.()}
          <button
            type="button"
            class={`p-1 my-1 rounded-md il-buttonlike-2 ml-auto ${props.offerDelete ? "" : "invisible"}`}
            data-test="delete-button"
            onClick={() => ctx.emit("delete")}
          >
            <X penColor="white" size="1em"/>
          </button>
        </div>
        <div>
          {ctx.slots.body?.()}
        </div>
      </div>
    }
  }
})

function paymentMethodDescription(pm: InvoiceTemplatePaymentMethod) {
  let text : string = ""

  {
    const trialOccurrences = parseIntOr(pm.trialOccurrences, null)
    if (typeof trialOccurrences === "number" && trialOccurrences > 0) {
      const paymentPlural = trialOccurrences === 1 ? "payment" : "payments"
      text += `${trialOccurrences} ${paymentPlural} of ${tryDollarFormat(pm.trialAmount, "<<missing trial amount>>")} and `
    }
  }

  {
    const nonTrialOccurences = parseIntOr(pm.totalOccurrences, null)
    if (nonTrialOccurences === null) {
      text += "<<no non-trial occurrences>>"
    }
    else {
      const paymentPlural = nonTrialOccurences === 1 ? "payment" : "payments"
      text += `${nonTrialOccurences} ${paymentPlural} of ${tryDollarFormat(pm.amount, "<<missing non-trial amount>>")} (Interval: Every ${pm.intervalLength || "<<no interval unit>>"} ${pm.intervalUnit})`
    }
  }

  return text

  function tryDollarFormat(v: "" | Floatlike, fallback: string) : string {
    const amount = parseFloatOr(v, null)
    return amount === null
      ? fallback
      : `$${amount.toFixed(2)}`
  }
}
