import { defineComponent, computed, watch, onBeforeMount, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { axiosAuthBackgroundInstance, freshAxiosInstance, freshNoToastLoggedInAxiosInstance } from 'boot/axios'

// Investigate: Do we need the side-effects of this import, for mobile builds?
// If we do need it, does it need to live here - can it be in the App main component?
import '@capacitor/core'

import iziToast from 'izitoast'
import PortletElem from 'src/components/Portlets/Portlet.vue'

import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { defaultCoachPhotoUploadModalController } from '../UserInterface/Modal.photoUpload.coach'
import { AutoModal } from "src/components/UserInterface/Modal"

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

import { Notifications } from "src/components/Navigational/Notifications"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { Btn2 } from '../UserInterface/Btn2'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { Guid } from 'src/interfaces/InleagueApiV1'
import { TabDef, Tabs } from '../UserInterface/Tabs'
import { getMungedPortlets, PortletsByID } from './Portlets.io'

import { assertTruthy, exhaustiveCaseGuard, useAbortController, vReqT } from 'src/helpers/utils'

import VolunteerCertifications from 'src/components/Portlets/VolunteerCertifications.vue'
import UpcomingGames from 'src/components/Portlets/UpcomingGames.vue'
import UpcomingEvents from 'src/components/Portlets/UpcomingEvents.vue'
import RefereeAssignments from 'src/components/Portlets/RefereeAssignments.vue'
import VolunteerStatus from 'src/components/Portlets/VolunteerStatus'
import DivisionStatistics from 'src/components/Portlets/DivisionStatistics'
import TeamAssignments from 'src/components/Portlets/TeamAssignments.vue'
import PlayersWithUnmetBirthCertificateRequirement from "src/components/Portlets/PlayersWithUnmetBirthCertificateRequirement"
import { DashboardRegistrationReport, XReport1 } from "src/components/Portlets/DashboardRegistrationReport"
import * as ilportlet from 'src/composables/InleagueApiV1.Portlet'
import authService from 'src/helpers/authService'
import { AxiosInstance } from 'axios'
import { SoccerBall } from '../SVGs'

export default defineComponent({
  name: 'Dashboard',
  setup() {
    const router = useRouter()

    onBeforeMount(async () => {
      if (System.value.isMobile && !User.isLoggedIn) {
        await router.push({ name: 'mobile-landing' })
      }
    })

    const abortController = useAbortController()
    const silentLoggedInNoToastAx = () => freshAxiosInstance({useCurrentBearerToken: true, signal: abortController.signal, requestInterceptors: [], responseInterceptors: []})

    const portletResolver = PortletResolver()
    const dashboardRegistrationReportResolver = DashboardRegistrationReportResolver()
    const {
      coachPhotoUploadModalController,
      needsCoachPhotoInfoResolver,
      coachPhotoUploadButton
    } = CoachPhotoInfo()

    /**
     * reload all the things when userID changes (i.e. login/logout, impersonation)
     */
    watch(() => User.value.userID, () => {
      coachPhotoUploadModalController.close()

      // The idea here is that _all_ resolvers have either their "load" or "reset" methods invoked when we're done here
      if (authZ_dashboardRegistrationReport()) {
        dashboardRegistrationReportResolver.load(silentLoggedInNoToastAx());
      }
      else {
        dashboardRegistrationReportResolver.reset()
      }


      if (User.value.userID) {
        needsCoachPhotoInfoResolver.load(silentLoggedInNoToastAx(), {userID: User.value.userID})
        portletResolver.load(silentLoggedInNoToastAx())
      }
      else {
        needsCoachPhotoInfoResolver.reset()
        portletResolver.reset()
      }
    }, {
      immediate: true
    })

    const shouldShowManagedPrograms = computed<boolean>(() => {
      const portlets = portletResolver.value.status === "resolved"
        ? portletResolver.value.data
        : null

      return authZ_dashboardRegistrationReport()
        || !!portlets?.divisionStats
    })

    const Loading = defineComponent(() => () => (
      <div class="p-2 flex items-center gap-2">
        <SoccerBall/>
        Loading...
      </div>
    ));

    const selectedTabIdx = ref(0)
    type DashboardTabDef = TabDef<"myInLeague" | "managedPrograms">
    const tabDefs = computed<DashboardTabDef[]>(() => {
      const result : DashboardTabDef[] = []

      if (/*always available*/ true) {
        result.push({
          id: "myInLeague",
          label: "My inLeague",
          "data-test": "myInLeague-button",
          render: () => {
            switch (portletResolver.value.status) {
              case "idle": return null
              case "pending": return <Loading/>
              case "error": return null // could show something...
              case "resolved": {
                const portlets = portletResolver.value.data

                return <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 px-1 pb-1">
                  {coachPhotoUploadButton.value.shouldShow
                    ? <CoachPhotoPortlet
                      userID={coachPhotoUploadButton.value.userID}
                      onOpenModal={args => {coachPhotoUploadModalController.open(args.userID)}}
                    />
                    : null}

                  {portlets.playersWithUnmetBirthCertificateRequirement
                    ? <PlayersWithUnmetBirthCertificateRequirement portlet={portlets.playersWithUnmetBirthCertificateRequirement}/>
                    : null}

                  {portlets.volStatus
                    ? <VolunteerStatus portlet={portlets.volStatus}/>
                    : null}

                  {portlets.certifications
                    ? <VolunteerCertifications showContent={true} certifications={portlets.certifications}/>
                    : null}

                  {portlets.upcomingGames
                    ? <UpcomingGames showContent={true} portlet={portlets.upcomingGames}/>
                    : null}

                  {portlets.upcomingEvents
                    ? <UpcomingEvents showContent={true} upcomingEvents={portlets.upcomingEvents}/>
                    : null}

                  {portlets.refereeAssignments
                    ? <RefereeAssignments showContent={true} assignments={portlets.refereeAssignments}/>
                    : null}

                  {portlets.playerTeamAssignments
                    ? <TeamAssignments showContent={true} children={portlets.playerTeamAssignments}/>
                    : null}
                </div>
              }
              default: exhaustiveCaseGuard(portletResolver.value)
            }
          }
        })
      }

      if (shouldShowManagedPrograms.value) {
        result.push({
          id: "managedPrograms",
          label: "Managed Programs",
          "data-test": "managedPrograms-button",
          render: () => {
            const hasSomePending = dashboardRegistrationReportResolver.value.status === "pending"
              || portletResolver.value.status === "pending"

            const resolvedReport = dashboardRegistrationReportResolver.value.getOrNull()
            const resolvedPortlets = portletResolver.value.getOrNull()

            return <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 px-1 pb-1">
              {hasSomePending
                ? <div style="grid-column:1/-1">
                  <Loading/>
                </div>
                : <>
                  {resolvedReport
                    ? <>
                      <XReport1 style="grid-column:-1/1;" class="bg-white rounded-md border p-2" report={resolvedReport}/>
                      <DashboardRegistrationReport report={resolvedReport}/>
                    </>
                    : null
                  }

                  {resolvedPortlets
                    ? <>
                      {resolvedPortlets.divisionStats
                        ? <DivisionStatistics showContent={true} divisionStats={resolvedPortlets.divisionStats}/>
                        : null}
                    </>
                    : null
                  }
                </>
              }
            </div>
          }
        })
      }

      return result;
    })

    /**
     * candidate to move into Tabs.tsx module
     */
    function makeTabDefMapping<T extends string>(tabDefs: TabDef<T>[]) {
      const id2Idx : Record<T, number> = {} as any
      const idx2Id : Record<number, T> = {}

      for (let i = 0; i < tabDefs.length; i++) {
        const tabDef = tabDefs[i]
        assertTruthy(tabDef.id, "tabDefs must have an id property here")
        id2Idx[tabDef.id as T] = i
        idx2Id[i] = tabDef.id as T
      }

      return {id2Idx, idx2Id}
    }

    const tabDefMapping = computed(() => makeTabDefMapping(tabDefs.value))

    watch(() => shouldShowManagedPrograms.value, () => {
      if (shouldShowManagedPrograms.value) {
        selectedTabIdx.value = tabDefMapping.value.id2Idx.managedPrograms
      }
      else {
        selectedTabIdx.value = tabDefMapping.value.id2Idx.myInLeague
      }
    }, {immediate: true})

    return () => {
      return <div data-cy="dashboard">
        <AutoModal data-test="CoachPhotoUploadModalController" controller={coachPhotoUploadModalController}/>
        <h1 class="text-4xl self-end font-medium">
          <FontAwesomeIcon class="mr-2" icon={faHome}/>
          Dashboard
        </h1>
        <div class="il-new-stacking-context">
          <Notifications/>
        </div>
        <Tabs
          class="mt-2"
          selectedIndex={selectedTabIdx.value}
          onChangeSelectedIndex={newIdx => selectedTabIdx.value = newIdx}
          tabDefs={tabDefs.value}
        />
      </div>
    }
  }
})

const CoachPhotoPortlet = defineComponent({
  props: {
    userID: vReqT<Guid>(),
  },
  emits: {
    openModal: (_: {userID: Guid}) => true,
  },
  setup(props, ctx) {
    return () => {
      return <PortletElem prefix="fas" icon="cog" label="Coach photo">
        <div class="p-2">
          <Btn2
            class="px-2 py-1"
            data-test="coachPhotoUploadButton"
            onClick={() => ctx.emit("openModal", {userID: props.userID})}
          >
            Upload photo
          </Btn2>
        </div>
      </PortletElem>
    }
  }
})

function CoachPhotoInfo() {
  const needsCoachPhotoInfoResolver = (() => {
    const resolver = ReactiveReifiedPromise<ilvolunteer.NeedsCoachPhotoInfo[]>()
    return {
      get value() { return resolver.underlying },
      load(ax: AxiosInstance, args: {userID: Guid}) {
        resolver.run(() => ilvolunteer.getWhoNeedsCoachPhotos(
          ax, {
          seasonUID: Client.value.instanceConfig.currentseasonuid,
          userIDs: [args.userID]
        }))
      },
      reset() {
        resolver.reset()
      }
    }
  })()

  const coachPhotoUploadButton = computed<{shouldShow: false} | {shouldShow: true, userID: iltypes.Guid}>(() => {
    if (needsCoachPhotoInfoResolver.value.status !== "resolved") {
      return {shouldShow: false}
    }

    return needsCoachPhotoInfoResolver.value.data.find(v => v.userID === User.value.userID && !v.isPhotoLocked && v.hasAtLeastOneCoachAssignmentForCompRequiringPhoto)
      ? {shouldShow: true, userID: User.value.userID}
      : {shouldShow: false}
  })

  const coachPhotoUploadModalController = reactive(
    defaultCoachPhotoUploadModalController(
      axiosAuthBackgroundInstance,
      (userID) => {
        iziToast.success({message: "Photo uploaded"})
        if (needsCoachPhotoInfoResolver.value.status !== "resolved") {
          return
        }

        // on success, simulate receiving an update from the backend that this user's photo status is now locked
        const localUpdateable = needsCoachPhotoInfoResolver.value.data.find(_ => _.userID === userID);
        if (localUpdateable) {
          localUpdateable.isPhotoLocked = true;
        }
      }
    ));

  return {
    needsCoachPhotoInfoResolver,
    coachPhotoUploadButton,
    coachPhotoUploadModalController
  }
}

function DashboardRegistrationReportResolver() {
  const resolver = ReactiveReifiedPromise<ilportlet.DashboardRegistrationReport>()

  // typically this resolves instantly
  // but it can sit and wait until the backend initializes a cache,
  // and that cache might be being initialized by someone else, and it is slow to init
  const pollUntilResolvedOrUnmounted = async (ax: AxiosInstance) => {
    while (true) {
      const result = await ilportlet.getDashboardRegistrationReport(ax)
      if (result === "EBUSY") {
        await new Promise(resolve => setTimeout(resolve, 15_000))
      }
      else {
        return result;
      }
    }
  }

  return {
    get value() { return resolver.underlying },
    load: (ax: AxiosInstance) => resolver.run(() => pollUntilResolvedOrUnmounted(ax)),
    reset: () => resolver.reset()
  }
}

function PortletResolver() {
  const resolver = ReactiveReifiedPromise<PortletsByID>()
  return {
    get value() { return resolver.underlying },
    load: (ax: AxiosInstance) => resolver.run(() => getMungedPortlets(ax)),
    reset: () => resolver.reset()
  }
}


function authZ_dashboardRegistrationReport() {
  const isLoggedIn = User.isLoggedIn;
  const isRegistrar = authService(User.value.roles, "registrar")
  const managesSomeCompetition = typeof User.value.userData === "object" && User.value.userData.competitionsMemento.length > 0;
  return isLoggedIn && (isRegistrar || managesSomeCompetition)
}
