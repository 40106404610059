<template lang="pug">
.flex.flex-col(data-test="Players")
  BirthCertificateUploadModal(
    v-if="birthCertificateUploadModalController.visible"
    v-bind="birthCertificateUploadModalController.props"
    v-on="birthCertificateUploadModalController.handlers"
  )
  AutoModal(
    data-test="PlayerPhotoUploadModalController"
    :controller="playerPhotoUploadModalController"
  )
  .overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
    h3.bg-gray-200.min-w-full.rounded-t-lg.px-4.py-2.font-medium Players
    .align-middle.inline-block.min-w-full
      .shadow.overflow-hidden.border-b.border-gray-200
        table.min-w-full.divide-y.divide-gray-200
          thead.bg-gray-50
            tr
              th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Name
              th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Birth Date
              th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Registrations
              th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col'
              ) Registration Status
              th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(
                scope='col',
                v-if='tryoutDetails.withTryouts'
              ) Tryouts
              //- th.px-3.py-1.text-left.text-xs.font-medium.text-gray-500.uppercase.tracking-wider(scope='col') Medical Release
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='player in family.children', :key='player.childID' :data-test="`playerID=${player.childID}`")
              td.px-3.py-2.text-sm.font-medium.text-gray-
                template(v-if="!hasPlayerEditorRoutePerms || player.registrationID == ''")
                  div {{ player.playerFirstName }} {{ player.playerLastName }}
                template(v-else)
                  router-link(:to="{name: 'player-editor', params: { playerID: player.childID, registrationID: player.registrationID }}")
                    div {{ player.playerFirstName }} {{ player.playerLastName }}
              td.px-3.py-2.text-sm.text-gray-500
                | {{ dayJSDate(player.playerBirthDate) }}
              td.px-3.py-2.text-sm.text-gray-500
                //- didn't think the :key attribute here was necessary, but playwright was flakey with the selector,
                //- seemingly implying that this wasn't updating appropriately without it
                div.divide-y(v-if='childCompDivRenderableMap[player.childID]' :data-test="`child-comp-divs-for-child-${player.childID}`" :key="player.childID")
                  div(v-for="entry in childCompDivRenderableMap[player.childID]" :data-test="`competitionUID=${entry.competitionUID}/divisionID=${entry.divID}`")
                    div(v-if='entry.status === "speculative"' :data-test="entry.status")
                      div(class="text-[.75em]") No current registrations, but would likely be placed in
                      div(class="pl-2 mt-[-4px]") {{ entry.competitionUiName }}, {{entry.divisionUiName}}
                    div(v-else-if='entry.status === "waitlisted/paid" || entry.status === "waitlisted/pending-payment"' :data-test="entry.status")
                      div(class="text-[.675em]") Waitlisted in
                      div(class="pl-2 mt-[-4px]") {{ entry.competitionUiName }}, {{ entry.divisionUiName }}
                    div.font-extrabold.text-green-700(v-else-if='entry.status === "active"' :data-test="entry.status")
                      div(class="text-[.675em]") Registered in
                      div(class="pl-2 mt-[-4px]") {{ entry.competitionUiName }}, {{ entry.divisionUiName }}
                    div(v-else-if='entry.status === "payment-processing"' :data-test="entry.status")
                      div(class="text-[.675em]") Payment processing
                      div(class="pl-2 mt-[-4px]") {{ entry.competitionUiName }}, {{ entry.divisionUiName }}
              td.px-3.py-2.text-sm.text-gray-500
                div.flex.flex-col.align-center
                  div
                    router-link(
                      v-if='player.registrationID'
                      :to='{ name: "player-editor", params: { playerID: player.childID, registrationID: player.registrationID } }',
                    )
                      t-btn(type='button')
                        div Update Registration
                  div(v-if="shouldShowBirthCertificateUpload[player.childID]" data-test="uploadBirthCertificateContainer")
                    t-btn(type="button" @click="birthCertificateUploadModalController.show(player)")
                      div
                        template(v-if="!player.hasSomeBirthCertificateFile")
                          div Upload birth certificate
                        template(v-else)
                          div Replace current birth certificate
                    div(v-if="player.hasSomeBirthCertificateFile" class="text-xs")
                      | Birth certificate is pending review by league registrar.
                  div(v-if="shouldShowPlayerPhotoUploadButton[player.childID]")
                    t-btn(type="button" @click="playerPhotoUploadModalController.open(player)" data-test="uploadPlayerPhoto") Upload player photo

              //- td.px-3.py-2.whitespace-nowrap.text-sm.font-medium
                //- | {{ player.medicalRelease}}
              td.px-3.py-2.text-sm.font-medium.text-gray-900(
                v-if='tryoutDetails.withTryouts'
              )
                div(
                  v-for='{ label, routeLocation } in tryoutDetails.buttonsPerChild[player.childID]'
                )
                  router-link(:to="routeLocation")
                    t-btn(type="button" :margin="false" data-test="tryout-event-link")
                      | {{ label }}
        .my-2.ml-2(class="sm:flex")
          div(v-if="family.children && family.children.length>0")
            router-link(
              :to='{ name: "select-player", params: { seasonUID: seasonUID } }',
            )
              FormKit(type='button', label='Register Players')
          div
            router-link(
              :to='{ name: "add-player", params: { familyID: family.familyID } }'
            )
              FormKit(type='button', label='Add New Player')
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import type { PropType } from 'vue'
import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { dayJSDate, dayJSTime } from 'src/helpers/formatDate'

import type { FamilyData } from 'src/composables/InleagueApiV1.Family'
import * as ilapi from 'src/composables/InleagueApiV1'
import type {
  ChildDivisionsForUserSeason,
  ChildID,
  DivisionID,
  Guid,
  TryoutEvent,
  Division,
  Registration,
  Child,
Competition,
CompetitionDivisionMatch,
DateTimelike,
} from 'src/interfaces/InleagueApiV1'
import BirthCertificateUploadModal from "src/components/PlayerEditor/BirthCertificateUploadModal.vue"
import * as M_BirthCertificateUploadModal from "src/components/PlayerEditor/BirthCertificateUploadModal.ilx"
import * as R_EventSignup from "src/components/Events/R_EventSignup.route"
import { type RouteLocationRaw } from 'vue-router'

import { AutoModal } from "src/components/UserInterface/Modal"
import { defaultPlayerPhotoUploadModalController } from "src/components/UserInterface/Modal.photoUpload.player"
import * as ilplayer from "src/composables/InleagueApiV1.Player"
import { unreachable, useIziToast } from 'src/helpers/utils'
import * as M_PlayerEditor from "src/components/PlayerEditor/PlayerEditor.shared"
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'
import dayjs from 'dayjs'

const props = defineProps({
  seasonUID: {
    type: String,
    required: true,
  },
  family: {
    required: true,
    type: Object as PropType<FamilyData>,
  },
  /**
   * todo: clarify what guarantees we have about the nature of the registration here
   * Does it contain only the competition registrations for the target season? Only the single non-canceled one (if it exists)?
   * Right now this appears to not contain any expanded compregs
   *
   * null if the child does not have a primary registration for this season
   */
  childRegistrationMapping: {
    required: true,
    type: Object as PropType<{[childID: Guid]: Registration | null}>
  }
})

type TryoutDetails =
  | { withTryouts: false }
  | {
      withTryouts: true
      buttonsPerChild: Record<ChildID, { label: string; routeLocation: RouteLocationRaw }[]>
    }

const tryoutDetails = ref<TryoutDetails>({ withTryouts: false })

/**
 * A renderable item in a player's division column
 * This are unique per (child, comp, div), where child is implied by usage context
 */
interface ChildCompDivRenderable {
  competitionUID: Guid,
  competitionUiName: string
  divID: Guid,
  divisionUiName: string
  status: "incomplete" | "waitlisted/paid" | "waitlisted/pending-payment" | "active" | "payment-processing" | "speculative"
  /**
   * should be a datelike in the `status=payment-processing` case, otherwise undefined
   */
  paymentProcessingAsOf: DateTimelike | undefined
}


/**
 * each child has some (possibly empty) list of competition registrations
 * This maps (child -> renderableInfo[])
 * The renderable is expected contain everything necessary for use in a template
 */
const childCompDivRenderableMap = ref<
  Record<ChildID, ChildCompDivRenderable[]>
>({})



const birthCertificateUploadModalController = M_BirthCertificateUploadModal.DefaultController({
  successfulUpload: (player) => {
    player.hasSomeBirthCertificateFile = true;
    birthCertificateUploadModalController.hide();
  },
  close: () => {
    birthCertificateUploadModalController.hide();
  }
})

const compDivMatchesByChild = ref<Record<ChildID, CompetitionDivisionMatch[]>>({});
const competitions = ref<readonly Competition[]>([]);

//
// to compute this, we need to know what competitions a player is registered in.
// The direct entity path is (family -> player -> (reg [or season would be enough too]) -> compreg), but we only have (family -> player -> reg) here.
// we could expand all the compregs but that would be pretty slow. We already have a mapping by way of the CompetitionDivisionMatch records,
// so we'll leverage that.
//
const shouldShowBirthCertificateUpload = computed<{[childID: Guid]: boolean}>(() => {
  const result : {[childID: Guid]: boolean} = {};

  // fixme -- prop type should clearly indicate that `family.children` is "definitely expanded";
  // can `.children` really be falsy here?
  for (const child of props.family.children ?? []) {
    result[child.childID] = M_BirthCertificateUploadModal.shouldShowBirthCertificateUpload(child, competitionsPlayerIsRegisteredIn(child.childID));
  }

  return result;
})

const shouldShowPlayerPhotoUploadButton = computed<{[childID: Guid]: boolean}>(() => {
  const result : {[childID: Guid]: boolean} = {};

  // fixme -- prop type should clearly indicate that `family.children` is "definitely expanded";
  // can `.children` really be falsy here?
  for (const child of props.family.children ?? []) {
    const hasSomeStackKey = !!child.stackRecordKey;
    const isPhotoLocked = child.isPhotoLocked === 1 || child.isPhotoLocked === "1" || child.isPhotoLocked === true;
    const hasSomeCompRequiringPhotos = competitionsPlayerIsRegisteredIn(child.childID).some(comp => !!comp.requirePlayerPhotos)
    result[child.childID] = !isPhotoLocked && hasSomeCompRequiringPhotos && hasSomeStackKey;
  }

  return result;
})

function competitionsPlayerIsRegisteredIn(childID: Guid) : Competition[] {
  const matches = compDivMatchesByChild.value[childID];
  if (!matches) {
    return [];
  }
  else {
    return matches
      // drop anything that isn't (paid=1, canceled=0, waitlist=0)
      .filter(v => !v.speculative && v.paid && !v.waitlist && !v.isWaitlistedButWithUnpaidBlockedPayment)
      // map entries to actual competitions
      .map(v => competitions.value.find(c => c.competitionUID === v.competitionUID))
      // filter away misses (there should never be any misses to filter away, but lets not leave falsy values around to crash later)
      .filter((v) : v is Competition => !!v) ?? []
  }
}

const iziToast = useIziToast();

const playerPhotoUploadModalController = reactive(
  defaultPlayerPhotoUploadModalController(
    axiosAuthBackgroundInstance,
    () => iziToast.success({message: "Photo uploaded"})
  )
);

onMounted(async () => {
  competitions.value = (await getCompetitionsOrFail()).value

  const childDivisionListing = await (async () => {
    const childDivisionsForUserSeason: ChildDivisionsForUserSeason =
      await Client.getChildDivisionsForUserSeason({
        userID: props.family.parent1ID,
        seasonUID: props.seasonUID,
      });
    return childDivisionsForUserSeason.result
  })()

  compDivMatchesByChild.value = childDivisionListing;

  if (Client.value.instanceConfig.usetryouts) {
    const tryoutEvents = await ilapi.getTryoutEventsForSeason(
      axiosInstance,
      props.seasonUID
    )
    if (tryoutEvents.length > 0) {
      const lookingForEventsThatBindToTheseDivIDs = (() => {
        const v = new Set<string>()
        for (const childID of Object.keys(childDivisionListing)) {
          for (const entry of childDivisionListing[childID]) {
            v.add(entry.divID)
          }
        }
        return v
      })()

      const k_universalEventTag = "*"

      const bestEventPerDivisionMap = (() => {
        // a universal event is an event that has no entries in its division listing, meaning "binds to any division"
        // the "best" one is just the first one we encounter like that
        let foundBestUniversalEvent = false
        const result : {[divID: /* Guid | k_universalEventTag */ string]: TryoutEvent | undefined} = {};

        // iterate over `tryoutEvents`
        // which are assumed to be ordered chronologically, soonest at [0], next soonest at [1], and so on
        //
        // we have effectively:
        //   T:          {..., divisions: Guid[]}
        //   source:     T[]
        //   lookingFor: Guid[]
        //
        // for each (T': T) in source
        //   T'.divisions empty?
        //     if haven't found best universal binder, this is it
        //     otherwise, no-op
        //     continue
        //   for each (divid: Guid) in T'.divisions
        //     lookingFor contains divid ?
        //       yes: delete divid from lookingFor, add T' to best match for divid
        //       no: no-op
        //     continue
        outer: for (const tryoutEvent of tryoutEvents) {
          if (tryoutEvent.divisions.length === 0) {
            if (foundBestUniversalEvent) {
              // no-op, already found the best universal event
            } else {
              foundBestUniversalEvent = true
              result[k_universalEventTag] = tryoutEvent
            }
            continue
          } else {
            for (const eventBindsToThisDivID of tryoutEvent.divisions) {
              if (
                lookingForEventsThatBindToTheseDivIDs.has(eventBindsToThisDivID)
              ) {
                result[eventBindsToThisDivID] = tryoutEvent
                lookingForEventsThatBindToTheseDivIDs.delete(
                  eventBindsToThisDivID
                )
                if (lookingForEventsThatBindToTheseDivIDs.size === 0) {
                  break outer
                }
              }
            }
          }
        }

        return result
      })()

      const buttonsPerChild = (() => {
        const result: Record<ChildID, { label: string; routeLocation: RouteLocationRaw }[]> = {}
        for (const childID of Object.keys(childDivisionListing)) {
          result[childID] = []

          // A player may have multiple registrations for 1 division, but our work here is keyed on division
          const seenDivIDs = new Set<Guid>()

          // Matches by div might yield the same "universal" event across different divisions, but we don't
          // want to see the same resulting event twice. The tryout events we're looking for are all pulled
          // from the same source on each iteration, so a Set<> with object identity semantics makes sense.
          const seenTryoutEvents = new Set<TryoutEvent>()

          for (const {divID} of childDivisionListing[childID]) {
            if (seenDivIDs.has(divID)) {
              continue;
            }
            else {
              seenDivIDs.add(divID)
            }

            const tryoutEvent =
              bestEventPerDivisionMap[divID] ??
              bestEventPerDivisionMap[k_universalEventTag] ??
              null

            if (tryoutEvent) {
              if (seenTryoutEvents.has(tryoutEvent)) {
                continue;
              }
              else {
                seenTryoutEvents.add(tryoutEvent)
              }

              result[childID].push({
                label: `${tryoutEvent.eventName.trim()} ${dayJSDate(
                  tryoutEvent.eventStart.trim()
                )}  @${dayJSTime(tryoutEvent.eventStart.trim())}`,
                routeLocation: R_EventSignup.routeDetailToRoutePath({name: R_EventSignup.RouteName.main, eventID: tryoutEvent.eventID})
              })
            } else {
              // no match for this division (no event references it, and there is "universal" event)
              // nothing to render
              continue
            }
          }
        }
        return result
      })()

      tryoutDetails.value = { withTryouts: true, buttonsPerChild }
    }
  }

  const divisionMap = await (async () => {
    const divisions: Division[] = await Client.getDivisions();
    const result: Record<DivisionID, Division> = {}
    for (const division of divisions) {
      result[division.divID] = division
    }
    return result
  })()

  childCompDivRenderableMap.value = (() => {
    const result: Record<ChildID, ChildCompDivRenderable[]> = {}

    for (const childID of Object.keys(childDivisionListing)) {
      const builderByDivIdByCompUid : {[competitionUID: Guid]: {[divID: Guid]: ChildCompDivRenderable}} = {};

      for (const entry of childDivisionListing[childID]) {
        // we shouldn't ever end up in the "<entity?>" cases,
        // but if we do, at least we won't crash
        const divisionUiName = divisionMap[entry.divID]?.displayName || divisionMap[entry.divID]?.division || "<division?>"
        const competitionUiName = entry.competitionUiName || "<competition?>";

        builderByDivIdByCompUid[entry.competitionUID] ??= {};

        if (entry.speculative) {
          builderByDivIdByCompUid[entry.competitionUID][entry.divID] = {
            competitionUID: entry.competitionUID,
            competitionUiName: competitionUiName,
            divID: entry.divID,
            divisionUiName: divisionUiName,
            status: "speculative",
            paymentProcessingAsOf: undefined,
          };
        }
        else {
          const {paid, waitlist, awaitingAsyncPaymentCompletion} = entry;

          let status : ChildCompDivRenderable["status"];

          if (awaitingAsyncPaymentCompletion) {
            status = "payment-processing"
          }
          else {
            // 0
            if (!paid && !waitlist) {
              continue;
            }
            // 1
            else if (!paid && waitlist) {
              if (entry.isWaitlistedButWithUnpaidBlockedPayment) {
                // they got through to the payment screen at least once
                // "pending-payment" is "registrar hasn't yet approved this submission, so the submitter isn't allowed to pay yet"
                status = "waitlisted/pending-payment";
              }
              else {
                continue;
              }
            }
            // 2
            else if (paid && !waitlist) {
              status = "active"
            }
            // 3
            else if (paid && waitlist) {
              status = "waitlisted/paid"
            }
            // all 4 combinations handled
            else {
              unreachable()
            }
          }

          builderByDivIdByCompUid[entry.competitionUID][entry.divID] = {
            competitionUID: entry.competitionUID,
            competitionUiName,
            divID: entry.divID,
            divisionUiName: divisionUiName,
            status,
            paymentProcessingAsOf: status === "payment-processing"
              ? dayjs(awaitingAsyncPaymentCompletion).format("MMM DD, YYYY h:mm a")
              : undefined,
          };
        }
      }

      result[childID] = [];

      for (const competitionUID of Object.keys(builderByDivIdByCompUid)) {
        for (const divID of Object.keys(builderByDivIdByCompUid[competitionUID])) {
          result[childID].push(builderByDivIdByCompUid[competitionUID][divID]);
        }
      }
    }
    return result
  })()
})

const hasPlayerEditorRoutePerms = computed(() => M_PlayerEditor.hasRoutePerms())
</script>
