import { computed, defineComponent, ref, watch } from "vue"

import Portlet from "src/components/Portlets/Portlet.vue"

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilportlet from 'src/composables/InleagueApiV1.Portlet'

import { arraySum, exhaustiveCaseGuard, parseFloatOr, parseIntOr, parseIntOrFail, sortBy, sortByDayJS, sortByMany, vReqT } from "src/helpers/utils"
import { DAYJS_FORMAT_IL_FULL_1, dayjsFormatOr, dayjsOr } from "src/helpers/formatDate"
import { MenuTree, freshSelectionsFirstOfEach } from "../UserInterface/MenuTree"
import { RouterLink } from "vue-router"
import * as R_AdminPage from "src/components/Admin/SeasonCompetitions/pages/R_AdminPage.route"
import dayjs from "dayjs"
import { freshSortState, inferColIDs } from "src/modules/TableUtils"
import { DashboardRegistrationStat } from "src/composables/InleagueApiV1.Portlet"
import { typedBasicAutoTable2Props, BasicAutoTable2 } from "src/modules/BasicAutoTable"
import { NoRender } from "src/helpers/NoRender"

export const DashboardRegistrationReport = defineComponent({
  name: "DashboardRegistrationReport",
  props: {
    report: vReqT<ilportlet.DashboardRegistrationReport>(),
  },
  setup(props) {
    const menuSelections = ref({
      competitionUID: "",
      seasonUID: "",
    })

    watch(() => props.report, () => {
      menuSelections.value = freshSelectionsFirstOfEach(props.report.menuDef);
    }, {immediate: true})

    const selectedReportEntry = computed(() => {
      return props.report.stats.find(v => v.competitionUID === menuSelections.value.competitionUID && v.seasonUID === menuSelections.value.seasonUID)
    })

    const selectedFeeDetails = computed(() => {
      const feesByDiv = props.report.feesByDivBySeasonByComp[menuSelections.value.competitionUID]?.[menuSelections.value.seasonUID]

      if (!feesByDiv) {
        return undefined;
      }

      return Object
        .values(feesByDiv)
        // drop optionality; typing artifact, shouldn't be strictly necessary
        .filter((v) : v is ilportlet.DashboardRegistrationReportFeeInfo_One => !!v)
        .sort(sortByMany(
          sortBy(_ => parseIntOr(_.divNum, -1), "asc"),
          sortBy(_ => _.gender)
        ))
        // Keep only the ones where we were able to compute at least one fee.
        // Typically, it's "all or nothing", where the backend either was able to compute, per (comp,season,div), a fee for all "ifRegisteringOn" dates,
        // or was unable to compute a fee for all "ifRegisteringOn" dates.
        // But the data structure is able to represent "some were computed, some weren't"
        .filter(v => v.fees.filter(_ => parseFloatOr(_.fee, null) !== null).length > 0)
    })

    const openOrSoonOpenCompSeasonText = (v: ilportlet.DashboardRegistrationReport["openOrSoonOpenCompSeasons"][number]) : string => {
      const regStart = dayjs(v.registrationStart)
      if (!regStart.isValid()) {
        return `${v.seasonName} - ${v.competition}`
      }

      const now = dayjs()
      const regStartFormatted =  regStart.format(DAYJS_FORMAT_IL_FULL_1)

      if (now.isAfter(regStart)) {
        return `${v.competition} - ${v.seasonName} (opened ${regStartFormatted})`
      }
      else {
        return `${v.competition} - ${v.seasonName} (opens ${regStartFormatted})`
      }
    }

    const portletLabel = "Registration Overview"
    const portletIcon = "clipboard-list"

    return () => {
      if (props.report.stats.length === 0) {
        // This is probably a mismatch between our local permissions checks and backend permissions filtering.
        // Could also possibly be legitimately no data, even for a well permissioned user, but such a case is probably very rare?
        return null;
      }
      return (
        <Portlet prefix="fas" icon={portletIcon} label={portletLabel}>
          <slot>
            <NoRender /* this section deprecated, see XReport1 */>
              <div>
                <div class="px-2 pb-2 max-h-32 overflow-y-auto">
                  <div>Programs open (or soon to be open) for registration:</div>
                  <div class="text-sm">
                    {
                      props.report.openOrSoonOpenCompSeasons.length === 0
                        ? <div>None</div>
                        : props.report.openOrSoonOpenCompSeasons.map(v => {
                            return (
                              <div>
                                {
                                  (() => {
                                    if (R_AdminPage.hasSpecificRoutePermission({competitionUID: v.competitionUID})) {
                                      const route = R_AdminPage.routeDetailToRouteLocation("season-competition-admin.seasonal",
                                        {
                                          competitionUID: v.competitionUID,
                                          seasonUID: v.seasonUID
                                        });
                                      return <RouterLink class="il-link" to={route}>{openOrSoonOpenCompSeasonText(v)}</RouterLink>
                                    }
                                    else {
                                      return <span>{openOrSoonOpenCompSeasonText(v)}</span>
                                    }
                                  })()
                                }
                              </div>
                            )
                          })
                    }
                  </div>
                </div>
                <div class="border-b border-gray-200"/>
              </div>
            </NoRender>
            {/*TODO: max height, the "card" layout should be grid based ... all the cards should have the same height ... per row ...*/}
            <div class="p-2 overflow-y-auto h-full" style="max-height:40em;">
              <MenuTree menuDef={props.report.menuDef} mut_selections={menuSelections.value}/>
              {
                selectedReportEntry.value
                  ? (
                    <div style="display: grid; grid-template-columns: max-content 1fr">
                      <Separator/>

                      <div class="mr-2">Registration start:</div>
                      <div>{dayjsFormatOr(selectedReportEntry.value.registrationStart, "MMM/DD/YYYY hh:mm a")}</div>
                      <Separator/>

                      <div class="mr-2">Registration end:</div>
                      <div>{dayjsFormatOr(selectedReportEntry.value.registrationEnd, "MMM/DD/YYYY hh:mm a")}</div>
                      <Separator/>

                      <div class="mr-2">Waitlist date:</div>
                      <div>{dayjsFormatOr(selectedReportEntry.value.waitlistDate, "MMM/DD/YYYY", "N/A")}</div>
                      <Separator/>

                      <div class="mr-2">Waitlist style:</div>
                      <div>{waitlistIsFreeUiString(selectedReportEntry.value.waitlistIsFree)}</div>
                      <Separator/>

                      <div class="mr-2">
                        <div>Players registered:</div>
                        <div class="text-xs">not waitlisted</div>
                      </div>
                      <div>{selectedReportEntry.value.activeRegistrationCount}</div>
                      <Separator/>

                      <div class="mr-2">
                        <div>Players registered:</div>
                        <div class="text-xs">waitlisted</div>
                      </div>
                      <div>{selectedReportEntry.value.waitlistedRegistrationCount}</div>
                      <Separator/>

                      <VolunteerDetails label="Head coach volunteers" vs={selectedReportEntry.value.headCoachVolCountsBySeasonDiv}/>
                      <Separator/>

                      <VolunteerDetails label="Asst coach volunteers" vs={selectedReportEntry.value.asstCoachVolCountsBySeasonDiv}/>
                      <Separator/>

                      <VolunteerDetails label="Referee volunteers" vs={selectedReportEntry.value.refVolCountsBySeasonDiv}/>
                      <Separator/>

                      <FeeDetails vs={selectedFeeDetails.value ?? []}/>
                      <Separator/>

                      {/* <div style="grid-column: 1/3" class="text-xs">
                        <div>{selectedCompetitionUID.value}</div>
                        <div>{selectedSeasonUID.value}</div>
                        <pre>{JSON.stringify(props.report.data.fees, null, 2)}</pre>
                      </div> */}

                      <div style="grid-column: 1/3" class="mt-2 text-xs">As of: {dayjsFormatOr(props.report.generatedOn, "MMM/DD/YYYY hh:mm a")}</div>
                      <div style="grid-column: 1/3" class="mt-2 text-xs">Note: Volunteer assignments are by season+division, and are not linked to a particular program.</div>
                    </div>
                  )
                  : null
              }
            </div>
          </slot>
        </Portlet>
      );

      function Separator() { return <div style="grid-column: 1 / 3" class="border-b border-gray-200 my-1"/> }

      function VolunteerDetails({label, vs}: {label: string, vs: ilportlet.SeasonDivVolunteerCount}) {
        return vs.countsByDiv.length === 0
            ? (
              <>
                <div class="mr-2">{label}:</div>
                <div>None</div>
              </>
            )
            : (
                <>
                  <div class="mr-2">{label}:</div>
                  <div>&nbsp;</div>
                  {
                    vs.countsByDiv.map(v =>
                      <>
                        <div class="mr-2">{v.displayName}</div>
                        <div>{v.count}</div>
                      </>
                    )
                  }
                  <div class="mr-2 font-medium">Total volunteer signups:</div>
                  <div>{arraySum(vs.countsByDiv.map(_ => parseIntOrFail(_.count)))}</div>
                  <div class="mr-2 font-medium">Unique volunteers across all signups:</div>
                  <div>{vs.uniqueRegistrants}</div>
                </>
            )
      }

      function FeeDetails({vs}: {vs: ilportlet.DashboardRegistrationReportFeeInfo_One[]}) {
        return vs.length === 0
            ? (
              <>
                <div class="mr-2">Registration fees:</div>
                <div>No fee info was retrieved</div>
              </>
            )
            : (
                <>
                  <div class="mr-2">Registration fees:</div>
                  <div>&nbsp;</div>
                  {
                    vs.map((v,i) => {
                      // `allFeesSame` also serves to prove that fees.length > 0
                      const allFeesSame = new Set(v.fees.map(feeInfo => dollarFormatOr(feeInfo.fee, "???"))).size === 1
                      const bgClass = i % 2 ? "bg-gray-100" : "";
                      return (
                        <>
                          <div class={[bgClass, "pr-2"]}>{v.displayName}</div>
                          <div class={[bgClass]}>
                            {
                              allFeesSame // if all fees are the same, just show it once
                                ? <div>{dollarFormatOr(v.fees[0].fee, "???")}</div>
                                : ( // each fee is potentially different, so show each (and the associated "this fee begins as of" date)
                                  v.fees.map(({fee, ifRegisteringOn}, i, a) =>
                                    <>
                                      <div>{dollarFormatOr(fee, "???")}</div>
                                      <div class="text-xs">Registration date {dayjsFormatOr(ifRegisteringOn, "MMM/DD/YY")}</div>
                                      {
                                        isLast(i, a)
                                          ? null
                                          : <Separator/>
                                      }
                                    </>
                                  )
                                )
                            }
                          </div>
                        </>
                      )
                    }
                    )
                  }
                </>
            );
        function isLast(i: number, a: unknown[]) {
          return i === a.length - 1;
        }
        function dollarFormatOr(v: number | string, fallback: string) : string {
          const r = parseFloatOr(v, undefined)?.toFixed(2);
          return r === undefined
            ? fallback
            : `$${r}`
        }
      }
    }
  }
})

function waitlistIsFreeUiString(v: iltypes.WaitlistIsFree_t["int"]) {
  switch (v) {
    case iltypes.WaitlistIsFree_t.WAITLIST_IS_FREE: return "Waitlist is free"
    case iltypes.WaitlistIsFree_t.WAITLIST_IS_NOT_FREE: return "Waitlist is not free"
    case iltypes.WaitlistIsFree_t.WAITLIST_GENERATES_TENTATIVE_PAYMENT_INTENT: return "Waitlist requires tentative payment"
    default: exhaustiveCaseGuard(v)
  }
}

// TODO: naming
export const XReport1 = defineComponent({
  name: "DashboardRegistrationReport-1",
  props: {
    report: vReqT<ilportlet.DashboardRegistrationReport>(),
  },
  setup(props) {
    const rowData = computed(() => {
      const openOrSoonOpenCompSeasons = new Set(
        props.report.openOrSoonOpenCompSeasons.map(v => `${v.competitionUID}/${v.seasonUID}`)
      )

      return props
        .report
        .stats
        .filter(v => openOrSoonOpenCompSeasons.has(`${v.competitionUID}/${v.seasonUID}`))
    })

    const colDefs = ref(inferColIDs<DashboardRegistrationStat>()([
      {
        id: "season",
        label: "Season",
        html: v => v.seasonName,
        sort: sortBy(v => v.seasonID),
      },
      {
        id: "program",
        label: "Program",
        html: v => {
          if (R_AdminPage.hasSpecificRoutePermission({competitionUID: v.competitionUID})) {
            const route = R_AdminPage.routeDetailToRouteLocation("season-competition-admin.seasonal",
              {
                competitionUID: v.competitionUID,
                seasonUID: v.seasonUID
              });
            return <RouterLink {...{target:"_blank"}} class="il-link" to={route}>{v.competition}</RouterLink>
          }
          else {
            return <span>{v.competition}</span>
          }
        },
        xlsx: v => v.competition,
        sort: sortBy(v => v.competitionID)
      },
      {
        id: "regStart",
        label: "Registration Start",
        html: v => dayjsOr(v.registrationStart)?.format(DAYJS_FORMAT_IL_FULL_1) || "",
        sort: sortByDayJS(v => v.registrationStart)
      },
      {
        id: "regEnd",
        label: "Registration End",
        html: v => dayjsOr(v.registrationEnd)?.format(DAYJS_FORMAT_IL_FULL_1) || "",
        sort: sortByDayJS(v => v.registrationEnd)
      },
      {
        id: "waitlistStart",
        label: "Waitlist Start",
        html: v => dayjsOr(v.waitlistDate)?.format(DAYJS_FORMAT_IL_FULL_1) || "",
        sort: sortByDayJS(v => v.waitlistDate)
      },
      {
        id: "waitlistStyle",
        label: "Waitlist Type",
        html: v => waitlistIsFreeUiString(v.waitlistIsFree),
        sort: sortBy(v => v.waitlistIsFree)
      }
    ]))

    const sortState = freshSortState(colDefs.value)
    sortState.reconfigure([{colID: "season", dir: "desc"}, {colID: "program", dir: "asc"}])

    return () => {
      return <div>
        <div>Programs open (or soon to be open) for registration:</div>
        <BasicAutoTable2
          {...typedBasicAutoTable2Props({
            colDefs: colDefs.value,
            sortState: sortState,
            rows: rowData.value,
            paginationOptions: [10, "ALL"],
            rowKey: row => `${row.competitionUID}/${row.seasonUID}`,
            //noData: () =vOptT<null | (() => JSX.Element | null)>(),
          })}
        />
      </div>
    }
  }
})