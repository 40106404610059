import { computed, defineComponent } from "vue"
import { FormKit, FormKitMessages } from "@formkit/vue"
import { FormKitNode } from "@formkit/core"

// TODO: move utils FK stuff to here
import { FK_nodeRef, FormKitValidationRule } from "./utils"

export type ValidationOutcome = {status: "unknown", msg?: string} | {status: "valid", msg?: string} | {status: "invalid", msg: string}

/**
 * Factory for a Vue component that runs a single validation rule using arbitrary validation logic.
 *
 * This element renders its own error message in-place (that is, wherever it is mounted in DOM).
 *
 * The goal here is to workaround FormKit's nonsense behavior with respect to interacting with the application's vue reactive data.
 * FormKit does not react to our own reactive data but instead bolts on its own reactive subsystem.
 * To work around this, we do the following:
 *
 * Accept a validation function, that is allowed to read our own reactive data, and WILL re-run when any reactive data touched in that function
 * is updated. More specifically, the provided function `f` will be evaluated inside a `computed`.
 *
 * The `status` result of the validation function is then forced into a 1-way binding USING 2-way binding, because formkit has decided
 * to repurpose the `value` attribute of its inputs to be the "initial value" of the input, rather than the standard 1-way binding as used in all other vue code;
 * we use a WriteableComputedRef to be a "dummy source" that responds to reads but DOES NOT respond to writes. In effect we use v-model to acheive a 1-way binding.
 *
 * A trivial formkit validator is then constructed locally that reads the result of the supplied validation function BY WAY OF the FormKit node on a mounted
 * hidden input (and take note -- reading the FormKit node (and NOT your application's vue reactive data) inside the validator is REQUIRED by FormKit
 * to maintain reactivity with respect to ensuring validation is re-run when things are mutated).
 *
 * Usage here is like:
 * ```
 * defineComponent({
 *   setup(props) {
 *     const MyValidationElem = FkDynamicValidation(() => props.foo === 42 && moonWaxesGibbous() ? {...} : {...})
 *     return () => <MyValidationElem/>
 *   }
 * })
 * ```
 *
 * INVESTIGATE: async version of this?
 */
export function FkDynamicValidation(f: () => ValidationOutcome) {
  const nodeRef = FK_nodeRef()

  const validationDummySource = computed<ValidationOutcome>({
    get() {
      return f()
    },
    set() {
      // no-op
    }
  })

  const validator = (node: FormKitNode) => {
    if (node.value === "unknown" || node.value === "valid") {
      return true
    }
    else {
      return false
    }
  }

  const validation : FormKitValidationRule[] = [["z"]]
  const validationRules = {z: validator}
  const validationMessages = computed(() => ({z: validationDummySource.value.msg || ""}))

  return defineComponent(() => () => <>
    <FormKit
      type="hidden"
      v-model={validationDummySource.value.status}
      validation={validation}
      validationRules={validationRules}
      validationMessages={validationMessages.value}
      ref={nodeRef}
    />
    <FormKitMessages node={nodeRef.value?.node}/>
  </>)
}
