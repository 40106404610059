<template lang="pug">
div(data-test="PaymentToolsRoot")
  AutoModal(:controller="achMandateModalController" class="max-w-lg" data-test="mandateModal")
  div(v-if="stripeCardElementReady" data-test="stripeCardElementReady")
    //-
    //- This div is intentionally empty
    //-
    //- stripeCardElementReady -- empty element that serves as a playwright hook;
    //- testing for this element's presence in DOM lets us know if stripe has completed its
    //- own mounting logic for its card element.
    //- We can't test directly for the stripe DOM element's presence because it must ALWAYS be present,
    //- even before being fully ready, because it is a container for stripe to place its own content into.
    //-
  .mt-4.m-2.px-2.py-2.border-2.border-solid.border-gray-300.rounded-lg.p-5(
    class='md:px-14 md:m-6 md:mt-6 md:py-6',
    v-if='paymentState && paymentState.requiresPaymentMethod'
  )
    h2.my-4.text-lg.leading-6.font-medium.text-gray-900
      component(:is="msg")
    template(v-if="nextAction?.type === 'verify_with_microdeposits'")
      div(
        class="shadow-sm border rounded-md"
        style="display:grid; grid-template-columns: min-content auto; grid-gap: 0 .5em;"
        data-test="blurb/verify_with_microdeposits"
      )
        div(class="text-yellow-400 bg-black rounded-tl-md rounded-bl-md px-1 flex items-center")
          font-awesome-icon(icon="fa-solid fa-triangle-exclamation" class="-mt-1")
        div(class="py-1")
          div An existing payment is pending bank verification.
          div
            | You can
            |
            a(
              :href="nextAction.verify_with_microdeposits.hosted_verification_url"
              class="il-link"
              target="_blank"
            )
              | verify your bank account
            |
            | or opt to pay with another payment method using the below form.
    .flex.flex-col.min-w-full
      .align-middle.inline-block.min-w-full.overflow-hidden.border-gray-200(
        class='sm:rounded-lg'
      )
        table.my-4.min-w-full.divide-y.divide-gray-200
          thead(v-if='paymentMethods.length')
            tr
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                |
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                |
              th.px-2.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(
                class='md:px-6'
              )
                | Last 4 Digits
              th.px-2.py-3.bg-gray-50.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.text-left(
                class='md:px-6'
              )
                | {{ exp }}
              th.px-2.py-3.bg-gray-50.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.text-left(
                class='md:px-6'
              )
                | Remove
          tbody.bg-white.divide-y.divide-gray-200
            tr(v-for='paymentMethod in paymentMethods')
              PaymentMethodListingTableCells(
                :paymentMethod="paymentMethod"
                :selectedPaymentMethodID="selectedPaymentMethodID_reflike"
                @deletePaymentMethod="() => deletePaymentMethod(paymentMethod)"
                @paymentMethodSelected="() => toggleCardSelected(paymentMethod.id)"
              )
            tr
              td.px-2.py-4.whitespace-nowrap.text-right.text-sm.flex.justify-center.items-center.leading-5.font-medium.align-top(
                v-if='paymentMethods.length',
                class='md:px-6'
              )
                t-singleRadio.w-4(
                  value='addCard',
                  :modelValue='selectedPaymentMethodID',
                  @change='toggleCardSelected',
                  @input='toggleCardSelected'
                  data-test="addPaymentMethod"
                  id="il-pm-addPaymentMethod"
                )
              td.text-sm(
                colspan=4,
                v-if='paymentMethods.length && selectedPaymentMethodID != "addCard"'
              )
                label(for="il-pm-addPaymentMethod")
                  | Add Payment Method
              td(
                colspan='5',
                v-show='selectedPaymentMethodID === "addCard" || paymentMethods.length === 0'
              )
                //-
                //- subscription invoices (for now, just those tournamentTeam(teamReg) invoices),
                //- need to have their paymentMethod saved, so we don't offer a choice in the matter.
                //- We __could__ allow ACH paymentMethods to not be saved, but this means allowing
                //- paymentIntents to enter the "verify_via_microdeposits" state, which we currently
                //- do not want to support (it introduces an additional layer of asynchronicity
                //- (the verify_via_microdeposits phase) on top of the already additional ach-pending-processing phase)
                //-
                label(
                  v-if="paymentState.entireInvoiceIs !== 'qTournamentTeam(teamReg)' && collectPaymentMethodDetailsType !== CollectPaymentMethodDetailsType.ach"
                  class="ml-2 inline-flex items-center gap-2"
                )
                  input(
                    type="checkbox"
                    class="rounded text-green-600 focus:ring-green-600"
                    v-model='savePaymentMethod'
                    data-cy='saveCard'
                  )
                  span Save payment method

                div(class="ml-2 flex gap-3 items-center my-1")
                  label(
                    v-if="allowedPaymentMethods.card"
                    class="flex items-center gap-2"
                  )
                    input(
                      type="radio"
                      name="collectPaymentMethodDetailsType"
                      v-model="collectPaymentMethodDetailsType"
                      :value="CollectPaymentMethodDetailsType.card"
                      :checked="collectPaymentMethodDetailsType === CollectPaymentMethodDetailsType.card"
                      data-test="cardMode"
                    )
                    span Card
                  label(
                    v-if="achEnabled && allowedPaymentMethods.ach"
                    class="flex items-center gap-2"
                  )
                    input(
                      type="radio"
                      name="collectPaymentMethodDetailsType"
                      v-model="collectPaymentMethodDetailsType"
                      :value="CollectPaymentMethodDetailsType.ach"
                      :checked="collectPaymentMethodDetailsType === CollectPaymentMethodDetailsType.ach"
                      data-test="achMode"
                    )
                    span ACH Debit
                .grid.grid-cols-1.gap-4.mx-2
                  .border-solid.border.border-gray-200.rounded-md.px-2.mr-4(
                    class='md:mr-0'
                  )
                    div.px-2.py-4.text-sm.leading-5.text-gray-500(v-if="collectPaymentMethodDetailsType === CollectPaymentMethodDetailsType.ach")
                      | Bank Account / ACH Debit
                      div
                        div
                          label(class="my-2 flex items-center gap-2")
                            input(
                              type="radio"
                              v-model="manuallyEnterAchDetails"
                              :value="false"
                              name="PaymentTools-achMethod"
                              data-test="stripeModalAchDetails"
                            )
                            div
                              //- TODO: user-visible verbiage that clearly differentiates "instant" vs "non-instant".
                              //- like "connect a bank account (generally instantly) | (using legacy ACH microdeposits mechanism)"
                              //- And that takes into consideration the fact that using the stripe modal will not always
                              //- result in instant verification, if the bank they are looking for is not in their provider list
                              //- and they fallback to the stripe modal's version of manual account number entry. Though,
                              //- it did seem there was a way to configure the modal to not allow manual entry, something like
                              //- 'require instant verification when using the modal' in one of the stripe calls that kicks off the
                              //- modal (or was it the setupIntent/paymentIntent paymentMethodOptions that configures that...)
                              p Pay by connecting a bank account.
                              p Connecting your bank account in this way is typically faster than manually entering your routing and account number.
                          Btn2(
                            v-if="!manuallyEnterAchDetails"
                            class="my-2 px-2 py-1" @click="checkoutVia_collectUsBankAccountInfo_stripeModal" data-test="addAchPaymentMethodViaStripeModal"
                          ) Submit payment by connecting a bank acount...
                        div(class="border-b border-dashed")
                        div
                          label(class="my-2 flex items-center gap-2")
                            input(
                              type="radio"
                              v-model="manuallyEnterAchDetails"
                              :value="true"
                              name="PaymentTools-achMethod"
                              data-test="manuallyEnterAchDetails"
                            )
                            div
                              p Manually enter a routing and account number.
                              p Account verification via this method may take a few days.
                        div(v-if="manuallyEnterAchDetails")
                          ManualAchInputForm(
                            :merchantOfRecord="merchantOfRecord"
                            @createPaymentMethod="checkoutVia_collectUsBankAccountInfo_manualAcctNumber"
                          )
                    div.px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500(v-if="collectPaymentMethodDetailsType === CollectPaymentMethodDetailsType.card")
                      | Credit Card
                      #card-element.mt-4(data-test='cardElement')
                      //- A Stripe Element will be inserted here.
                      //- Used to display form errors.
                      #card-errors(role='alert')
                      div
                        div.mt-2.font-light.text-red-600 {{ error }}
                        Btn2(
                          class="px-2 py-1 mt-2"
                          :disabled='paymentInProgress',
                          @click='getCardPaymentMethodAndSubmitPayment',
                          data-test='submitPayment/newPaymentMethod'
                        )
                          | {{ paymentButtonLabel }}
                        div(
                          class="text-sm font-medium mb-2"
                          v-if="paymentState.entireInvoiceIs === 'qTournamentTeam(teamReg)'"
                        )
                          //- nothing at this time
                  div
                    .px-2.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-solid.border-2.border-gray-200.rounded-md(
                      class='md:px-6',
                      v-if='AppleOrGooglePay'
                    )
                      div {{ AppleOrGooglePay === "A" ? "Apple" : AppleOrGooglePay === "G" ? "Google" : "" }} Pay
                      .mx-15(v-if='AppleOrGooglePay === "G"')
                        img.mt-4.self-center(
                          :src='"/app/google-pay-mark.svg"',
                          data-cy='googlePay',
                          @click='showPaymentRequest'
                        )
                      .pr-40(v-if='AppleOrGooglePay === "A"')
                        #payment-request-button.mt-4
        .m-2.flex.flex-row.justify-between.items-start
          //- button is visible if the "add payment method" pane is NOT visible,
          //- where the "add payment method" pane has its own submit payment button
          Btn2(
            v-if="selectedPaymentMethodID.startsWith('pm_')"
            class="px-3 py-2 mt-2"
            :disabled='paymentInProgress || selectedPaymentMethodUnusable',
            @click='getCardPaymentMethodAndSubmitPayment',
            data-test='submitPayment'
          )
            | {{ paymentButtonLabel }}
          div(
            class="text-sm font-medium mb-2"
            v-if="paymentState.entireInvoiceIs === 'qTournamentTeam(teamReg)'"
          )
            //- nothing at this time
          div(v-show="showPayWithCashOrCheckButton")
            div.ml-2.font-light {{ error }}
            t-btn.my-4.w-full.mr-8(
              :label="showPayWithCashOrCheckLabel",
              :disable='paymentInProgress',
              @click='payByCashOrCheck()',
              :margin='false',
              data-test='payWithCashOrCheck-button'
            )

  .flex.flex-col.items-center.justify-center.px-6.py-2(v-else)
    //- important null guard; TODO: JSXify for flow analysis' sake
    template(v-if="paymentState")
      template(v-if="paymentState.isBlocked")
        //- we're blocked and do not require any payment info
        //- we currently expect that informational blurbs be put on the screen by some other component,
        //- generally the Checkout.vue component which will draw lineitem-state-specific content chunks.
      template(v-else)
        //- no fee, but not blocked, "paying" is OK but "just" finalizes the order.
        div There weren't any fees associated with this invoice. Click below to finalize this order.
        t-btn.mt-2.w-full(:margin='false' @click='submitPayment' data-test="submit-zero-fee")
          span.w-full.text-center Confirm
</template>

<script lang="tsx">
import { defineComponent, ref, computed, Ref, watch, onMounted, nextTick, onBeforeUnmount, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'


import { Screen } from 'quasar'
import {
  loadStripe,
  PaymentMethod,
  PaymentRequestPaymentMethodEvent,
  StripeCardElement,
  Stripe as StripeInterface,
  StripeElementStyle,
  StripeElements,
} from '@stripe/stripe-js'

// todo: don't use aliases (just used them to make as small a changeset as possible when changing out types)
import { Event as EventObject, EventSignup as Signups } from "src/composables/InleagueApiV1.Event"
import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance, FALLBACK_ERROR_MESSAGE } from 'src/boot/axios'
import { v4 as uuidv4 } from 'uuid'
import { assertNonNull, assertTruthy, exhaustiveCaseGuard, forceCheckedIndexedAccess, parseFloatOr, parseFloatOrFail, unreachable, useIziToast, vReqT } from 'src/helpers/utils'
import { propsDef, emitsDef, maybeGetCompRegLineItems, maybeGetTournamentTeamRegLineItem, maybeGetEventSignups } from "./PaymentTools.ilx"
import { LastStatus_t } from 'src/interfaces/Store/checkout'
import { Integerlike } from 'src/interfaces/InleagueApiV1'
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"


import { PaymentMethodListingTableCells } from './PaymentToolsElems'

import { Client } from 'src/store/Client'

import { PayInvoiceArgs } from 'src/composables/InleagueApiV1.Invoice'
import { isSubscriptionInvoice } from './InvoiceUtils'
import { Btn2 } from "src/components/UserInterface/Btn2"
import { User } from 'src/store/User'
import { DefaultModalController_r } from '../UserInterface/Modal'
import { AchMandateModal, AchMandate } from './AchMandateModal'
import { AutoModal } from '../UserInterface/Modal'
import { FormKit } from '@formkit/vue'
import { FormKitNode } from "@formkit/core";
import { getLogger, maybeLog } from 'src/modules/LoggerService'
import { LoggedinLogWriter } from 'src/modules/Loggers'
import { type Stripe, stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal, stripe_createAchSetupIntent_manualAcctNumber, stripe_deleteSetupIntentPendingAchVerification, stripe_getPaymentIntentForInvoice, stripe_listCustomerPaymentMethods, stripe_trackMultiUseActiveMandate, stripeObjId } from './Payments.io'

import { IL_PaymentMethod } from "./Payments.io"

type CreateManualAchPaymentMethodEvent = {
  routingNumber: string,
  accountNumber: string
  accountHolderType: "individual" | "company"
}

const ManualAchInputForm = defineComponent({
  props: {
    merchantOfRecord: vReqT<string>(),
  },
  emits: {
    createPaymentMethod: (_: CreateManualAchPaymentMethodEvent) => true,
  },
  setup(props, ctx) {
    /**
     * The actual button label and the text in the mandate need to share this exactly.
     */
    const acceptLabel = "Submit Payment"

    const fk_sameAs = (node: FormKitNode, otherNodeName: string) => {
      const a = node.value;
      const b = node.root.find(otherNodeName)?.value
      return a === b;
    }

    const manualBankInfoAchMandateText = computed(() => {
      return AchMandate({
        acceptLabel,
        merchantOfRecord: props.merchantOfRecord,
      })
    });

    const handleSubmit = (formData: any) => {
      const routingNumber = formData.routingNumber
      const accountNumber = formData.accountNumber
      const accountHolderType = formData.accountHolderType
      ctx.emit("createPaymentMethod", {routingNumber, accountNumber, accountHolderType})
    }

    // we rely on the form to "manage its own data";
    // but there are some fields we want to dynamically control in test mode
    const controlledData = reactive({
      routingNumber: "",
      accountNumber: "",
      accountNumberVerify: "",
    })

    return () => {
      return (
        <FormKit type="form" actions={false} onSubmit={handleSubmit}>
          <FormKit
            type="text"
            {...{inputmode: "numeric"}}
            label="Routing No."
            name="routingNumber"
            validation={[["required"], ["matches", /^[0-9]{9}$/]]}
            validationMessages={{matches: "A routing number should be a 9-digit number."}}
            data-test="ach_routingNumber"
            v-model={controlledData.routingNumber}
          />

          {process.env.NODE_ENV === "development"
            ? <div class="rounded-md border inline-block mb-4">
              <div class="p-1 bg-black rounded-t-md text-white">dev stuff</div>
              <div class="p-1">
                <div>
                  <a class="il-link" onClick={() => {
                    controlledData.routingNumber = "110000000"
                    controlledData.accountNumber = controlledData.accountNumberVerify = "000123456789"
                  }}>
                    pm_usBankAccount_success
                  </a>
                </div>
                <div>
                  <a class="il-link" onClick={() => {
                    controlledData.routingNumber = "110000000"
                    controlledData.accountNumber = controlledData.accountNumberVerify = "000000000009"
                  }}>
                    pm_usBankAccount_processing
                  </a>
                </div>
              </div>
            </div>
            : null}

          {/*TODO: validation -- are bank accounts always 'just digits'?*/}
          <FormKit
            type="text"
            label="Account No."
            name="accountNumber"
            validation={[["required"]]}
            data-test="ach_accountNumber"
            v-model={controlledData.accountNumber}
          />
          <FormKit
            type="text"
            label="Account No. Verify"
            name="accountNumberVerify"
            validation={[["required"], ["sameAs", "accountNumber"]]}
            validationRules={{sameAs: fk_sameAs}}
            validationMessages={{sameAs: "Must be same as accountNumber."}}
            data-test="ach_accountNumberVerify"
            v-model={controlledData.accountNumberVerify}
          />

          <FormKit
            type="select"
            label="Account Type"
            name="accountHolderType"
            options={[
              {label: "Individual", value: "individual"},
              {label: "Business", value: "company"}
            ]}
            data-test="ach_accountHolderType"
          />

          <div class="text-xs my-2" style="text-wrap:wrap; max-width: calc(5em + var(--fk-max-width-input));">
            {manualBankInfoAchMandateText.value}
          </div>
          <Btn2
            type="submit"
            class="px-2 py-1"
            data-test="ach_submit"
          >
            {acceptLabel}
          </Btn2>
        </FormKit>
      )
    }
  },
})

export default defineComponent({
  name: 'PaymentTools',
  props: propsDef,
  emits: emitsDef,
  components: {
    Btn2,
    PaymentMethodListingTableCells,
    AutoModal,
    ManualAchInputForm,
  },
  setup(props, {emit}) {

    const spk = ref(Client.value.stripePublicKey)

    const allowedPaymentMethods = computed(() => {
      return {
        card: !!props.invoiceInstance.allowedPaymentMethods?.includes("card"),
        ach: !!props.invoiceInstance.allowedPaymentMethods?.includes("us_bank_account")
      } satisfies Record<string, boolean>
    })

    const card = ref<StripeCardElement | null>(null)
    const stripeCardElementReady = ref(false);

    enum CollectPaymentMethodDetailsType { card = "card", ach = "ach" }
    const collectPaymentMethodDetailsType = ref(CollectPaymentMethodDetailsType.card)
    watch(() => allowedPaymentMethods.value, () => {
      const allowed : CollectPaymentMethodDetailsType[] = (() => {
        const result : CollectPaymentMethodDetailsType[] = []
        if (allowedPaymentMethods.value.ach) {
          result.push(CollectPaymentMethodDetailsType.ach)
        }
        if (allowedPaymentMethods.value.card) {
          result.push(CollectPaymentMethodDetailsType.card)
        }
        return result
      })()

      if (!allowed.find(v => v === collectPaymentMethodDetailsType.value)) {
        // what would the fallback be if we ended up with no allowed payment methods?
        assertTruthy(allowed.length > 0)
        collectPaymentMethodDetailsType.value = allowed[0]
      }
    }, {immediate: true})
    const manuallyEnterAchDetails = ref(false)

    watch(() => collectPaymentMethodDetailsType.value, () => {
      if (collectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.ach) {
        card.value?.unmount()
      }
      else if (collectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.card) {
        const elements = stripe.value?.elements()
        if (!elements) {
          return;
        }

        // wait for next tick, so that card element which we will mount into is itself mounted
        setTimeout(() => initCardElement(elements), 0)
      }
      else {
        exhaustiveCaseGuard(collectPaymentMethodDetailsType.value)
      }
    });

    const stripe = ref<StripeInterface | null>(null)

    /**
     * empty string or a stripe paymentMethodID matching the pattern "^pm_"
     */
    const selectedPaymentMethodID = ref('')
    const paymentMethods = ref<IL_PaymentMethod[]>([])
    const selectedPaymentMethodUnusable = computed(() : boolean => {
      const Result_CAN_BE_USED = false; // "unusable is false" (can be used)
      const Result_CANNOT_BE_USED = true; // "unusable is true" (can not be used)
      if (!selectedPaymentMethodID.value.startsWith("pm_")) {
        // "nothing selected"
        return Result_CANNOT_BE_USED
      }

      const pm = paymentMethods.value.find(pm => pm.id === selectedPaymentMethodID.value)
      if (!pm) {
        return Result_CANNOT_BE_USED
      }

      if (pm.type === "us_bank_account" && pm.setupIntent?.next_action) {
        // The selected paymentMethod is for a us_bank_account that is not yet verified,
        // so it cannot be used until it is verified.
        return Result_CANNOT_BE_USED
      }

      // default for all other paymentMethods is "yes, it's usable"
      return Result_CAN_BE_USED
    })

    const achEnabled = ref(false)
    const achOfferInstantVerification = ref(false)
    const stripeAccount = ref('')
    const merchantOfRecord = ref("<<uninitialized>>")


    const savePaymentMethod = ref(true)
    const paymentInProgress = ref(false)

    /**
     * A message displayed in DOM for error cases
     */
    const error = ref('')

    const enrolled = ref({}) as Ref<{ [key: string]: Signups }>
    const event = ref({}) as Ref<EventObject>

    const msg = computed(() => {
      const overallTotal = parseFloatOr(props.invoiceInstance.lineItemSum, null)?.toFixed(2) ?? props.invoiceInstance.lineItemSum;

      if (isSubscriptionInvoice(props.invoiceInstance)) {
        return <div>{props.paymentScheduleBlurb}</div>
      }
      else {
        return <div>Your total is: ${overallTotal}</div>
      }
    })

    const exp = ref('Expiration')
    const AppleOrGooglePay = ref(null) as Ref<null | string>
    const paymentRequest = ref<null | import('@stripe/stripe-js').PaymentRequest>(null)

    const route = useRoute()
    const router = useRouter()

    const hasSomePaymentBlockedLineItem = computed(() => {
      for (const lineItem of props.invoiceInstance.lineItems) {
        if (lineItem.paymentBlock_isBlocked) {
          return true;
        }
      }
      return false;
    });

    const cardElementsStyle : StripeElementStyle = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }

    async function submitPayment(
      args?: {paymentMethodID: string}
    ) : Promise<{ok: boolean} | {ok: false, message?: string}>
    {
      paymentInProgress.value = true;

      try {
        if (hasSomePaymentBlockedLineItem.value) {
          // TODO: this exclusively handles a compreg case, where "the whole compreg is blocked",
          // so should be refactored to be explicit about that.
          const paymentMethodID = args?.paymentMethodID ?? selectedPaymentMethodID.value
          const invoiceInstanceID = props.invoiceInstance.instanceID as Integerlike;
          return await props.attachTentativeStripePaymentMethodToInvoice({instanceID: invoiceInstanceID, paymentMethodID});
        }
        else {
          const result = await props.payInvoice(getPayInvoiceArgs());
          if (!result.ok) {
            error.value = result.message;
          }
          return result;
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        // hm, there WON'T be any NON-axios exceptions here right? Meaning, `rethrowIfNotAxiosError` will always rethrow here?
        // Because the invoked things return the equivalent of Either<OK,Err>, rather than throw axios errors.
        return {ok: false, message: "Sorry, something went wrong"}
      }
      finally {
        paymentInProgress.value = false;
      }

      function getPayInvoiceArgs() : PayInvoiceArgs {
        return {
        instanceID: props.invoiceInstance.instanceID,
        idempotencyKey: uuidv4(),
        paymentMethodID: (() => {
            if (!paymentState.value) {
              // shouldn't happen
              return undefined
            }

            if (paymentState.value.entireInvoiceIs === "qTournamentTeam(teamReg)") {
              // for tournTeam teamReg invoices,
              // where teamReg is free but there is an associated non-zero holdPayment,
              // we want to enforce collection of the paymentMethod for the hold payment invoice,
              // before we allow the free registration invoice to be "paid".
              // The backend handles both at once when pushing "payment" for a tournteam reg invoice.
              return selectedPaymentMethodID.value;
            }

            const parsedSum = parseFloatOrFail(props.invoiceInstance.lineItemSum)
            if (parsedSum >= 0.01) {
              return selectedPaymentMethodID.value
            }

            return undefined;
          })(),
          discardCard: (() => {
            if (selectedPaymentMethodID.value === 'addCard' || !paymentMethods.value.length) {
              return !savePaymentMethod.value
            }
            else {
              return undefined;
            }
          })()
        };
      }
    }

    const stripeElements = (() => {
      let elements : StripeElements | null = null
      return {
        get() {
          assertNonNull(stripe.value)
          return (elements ??= stripe.value.elements())
        }
      }
    })();

    const initCardElement = (elements: StripeElements) => {
      if (!document.querySelector("#card-element")) {
        // We didn't mount the card-element container, so there's no target to offer to stripe utils.
        // Presumably, we didn't mount the container because paymentState.requiresPaymentMethod is false
        return;
      }

      card.value = elements.create('card', {style: cardElementsStyle})

      card.value.once("ready", () => {
        stripeCardElementReady.value = true;
      })

      card.value.mount('#card-element')

      // user clicks submit but there is an error
      // displaying $theErrorMessage ->
      // user types to try to fix the error ->
      // clear the message ->
      // user clicks submit (again) ->
      // make stripe request, reassign error.value if one was generated
      card.value.on("change", () => error.value = "");
    }

    // mounts elements for both card and apple/google pay button
    const mountStripe = async () => {
      // Mount card form

      stripe.value = await loadStripe(spk.value, {
        stripeAccount: stripeAccount.value,
      })

      if (stripe.value) {
        const elements = stripeElements.get()

        initCardElement(elements)

        // Mount apple/google pay button
        const paymentRequestLocal = stripe.value.paymentRequest({
          country: 'US',
          currency: 'usd',
          total: {
            label: `${Client.value.instanceConfig.shortname}`,
            amount: parseFloatOrFail(props.invoiceInstance.lineItemSum) * 100,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        })

        const result = await paymentRequestLocal.canMakePayment() as null | Record<"applePay" | "googlePay", boolean>

        if (result) {
          paymentRequest.value = paymentRequestLocal
          if (result.applePay) {
            AppleOrGooglePay.value = 'A'
            const prButton = elements.create('paymentRequestButton', {
              paymentRequest: paymentRequestLocal,
              style: {
                paymentRequestButton: {
                  height: '48px',
                },
              },
            })
            //
            // #payment-request-button is more like "apple-pay-payment-request-button" ?
            //
            // wait for page reflow in response to setting AppleOrGooglePay.value = 'A'
            // otherwise, the element identified by #payment-request-button is not present in DOM, and
            // asking to mount stripe stuff against it fails. Out of an abundance of caution we double await,
            // with intent to "redraw everything" (is it guaranteed that a single await will redraw, or could
            // we already be after that phase when we await the first nexttick)
            //
            // This might not solve the problem but it shouldn't make anything worse.
            //
            // todo: test that this 100% solves the issue
            // related: https://sentry.io/organizations/inleague-llc/issues/3768001648/?project=5661592
            //
            await nextTick();
            await nextTick();

            const targetElementSelector = '#payment-request-button'
            if (document.querySelector(targetElementSelector)) {
              // cool, we're still mounted, and the target element exists
              prButton.mount(targetElementSelector)
            }
            else {
              //
              // Presumably here we have been unmounted, maybe a user hit "back" while we were waiting on one
              // of the awaits above. If we don't guard this, stripe will throw an exception when trying to work with a DOM
              // element that doesn't exist.
              //
              // There's nothing we can do here.
              //
              // see: https://inleague-llc.sentry.io/issues/4404557090/?alert_rule_id=4761582&alert_timestamp=1692373867165&alert_type=email&environment=production&project=5661592&referrer=alert_email
              //
            }
          } else if (result.googlePay) {
            AppleOrGooglePay.value = 'G'
          }
        } else {
          // this was an assignment to a non-null-asserted lefthand side, but the non-null assertion was not correct (i.e. obj still falsy)
          // what does it mean if this element is not present?
          const maybePaymentRequestButton = document.getElementById('payment-request-button');
          if (maybePaymentRequestButton) {
            maybePaymentRequestButton.style.display = 'none'
          }
        }

        paymentRequestLocal.on(
          'paymentmethod',
          (evt: PaymentRequestPaymentMethodEvent) => {
            //// console.log('paymentMethod received', evt)
            if (evt.paymentMethod.id) {
              selectedPaymentMethodID.value = evt.paymentMethod.id
              submitPayment()
                .then(APIResponse => {
                  if (APIResponse.ok) {
                    evt.complete('success')
                  }
                  else {
                    evt.complete('fail')
                  }
                })
                .catch(err => {
                  // console.log('error', err)
                })
            } else {
              evt.complete('fail')
            }
          }
        )
      }
    }

    const showPaymentRequest = () => {
      // This is only for the GooglePay case, yeah?
      // It seems the ApplePay case will be triggered by some stripe callback responsible for the apple case
      // This is not called in the "default" stripe payment case.
      paymentRequest.value?.show()

      // This is duplicative w/ the handler already registered from within `mountStripe` right?
      // TODO: get reproducible tests, check that this would be (undesireably) fired twice (because we've registered 2 separate "onPaymentMethod" handlers)
      // paymentRequest.value.on(
      //   'paymentmethod',
      //   async (evt: PaymentRequestPaymentMethodEvent) => {
      //     if (evt.paymentMethod.id) {
      //       selectedPaymentMethodID.value = evt.paymentMethod.id
      //       await submitPayment().then(APIResponse => {
      //         if (APIResponse.ok) {
      //           evt.complete('success')
      //         }
      //         else {
      //           evt.complete('fail')
      //         }
      //       })
      //     } else {
      //       evt.complete('fail')
      //     }
      //   }
      // )
    }

    const hydratePaymentMethods = async () => {
      try {
        const r = await stripe_listCustomerPaymentMethods(axiosInstance, {clientGatewayID: props.invoiceInstance.paymentGatewayID}).then(data => {
          data.paymentMethods = data.paymentMethods.filter(v => {
            switch (v.type) {
              case "card": return allowedPaymentMethods.value.card
              case "us_bank_account": return allowedPaymentMethods.value.ach
              default: return false;
            }
          })
          return data;
        })

        stripeAccount.value = r.stripeAccountID;
        paymentMethods.value = r.paymentMethods;
        achEnabled.value = r.ach.enabled
        achOfferInstantVerification.value = r.ach.remainingInstantVerifications > 0
        merchantOfRecord.value = r.ach.connectAccountMerchantOfRecord;

        if (paymentMethods.value.length > 0) {
          const currentUiSelectionIsPresentInResponse = !!paymentMethods.value.find(pm => pm.id === selectedPaymentMethodID.value);
          if (!currentUiSelectionIsPresentInResponse) {
            selectedPaymentMethodID.value = forceCheckedIndexedAccess(paymentMethods.value, 0)?.id || "";
          }
        }
        else {
          selectedPaymentMethodID.value = ""
        }
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const toggleCardSelected = (value: string) => {
      selectedPaymentMethodID.value = value
    }

    const deletePaymentMethod = async (paymentMethod: IL_PaymentMethod) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          if (paymentMethod.setupIntent?.next_action?.type === "verify_with_microdeposits") {
            await stripe_deleteSetupIntentPendingAchVerification(axiosInstance, {
              clientGatewayID: props.invoiceInstance.paymentGatewayID,
              setupIntentID: paymentMethod.setupIntent.id
            })
          }
          else {
            await axiosInstance.delete(
              `v1/payments/${props.invoiceInstance.paymentGatewayID}/paymentMethods/${paymentMethod.id}`
            )
          }
          await hydratePaymentMethods()
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    // IMPORTANT: This gets the payment number from stripe for cards only
    const getCardPaymentMethodAndSubmitPayment = async () : Promise<void> => {
      paymentInProgress.value = true

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (selectedPaymentMethodID.value === 'addCard' || !paymentMethods.value.length) {
          try {
            if (stripe.value && card.value) {

              const response = await stripe.value.createPaymentMethod({
                type: 'card',
                card: card.value,
              })

              if (response.error) {
                iziToast.error({message: response.error.message})
                error.value = response.error.message || "Sorry, something went wrong."
                paymentInProgress.value = false;
                return;
              }
              else if (response.paymentMethod) {
                selectedPaymentMethodID.value = response.paymentMethod.id
              }
              else {
                // ? not an error, but no paymentMethod?
                // this code has been falling though, so we'll let it do that;
                // But probably can never get here?
              }
            }
          } catch (err: any) {
            const msg = err.response.message
            iziToast.error({message: msg})
            error.value = msg
            paymentInProgress.value = false
            return
          }
        }

        await submitPayment()
      })
    }

    const payByCashOrCheck = () => {
      emit("payWithCashOrCheck");
    }

    /**
     * fixme: see propsDef for `total` -- it's undefined, and a string?
     */
    const mungedTotal = computed<number | null>(() => parseFloatOr(props.total, null));

    /**
     * Conceptually, an invoice can be composed of many heterogenous line item types,
     * like a compreg and 2 event signups and a tournament team registration all on one invoice.
     * But in practice, an invoice is always homegenous, with the exception of donation line items being included
     * on compreg invoices. This may change in the future; but for now this encodes the use case.
     *
     * `requiresPaymentMethod` and `isBlocked` are related but ultimately orthogonal:
     * requiresPaymentMethod=0 isBlocked=0 -> totally zero-fee invoice, click to finalize
     * requiresPaymentMethod=0 isBlocked=1 -> totally zero-fee invoice, can't finalize until block is removed (e.g. a waitlisted compreg)
     * requiresPaymentMethod=1 isBlocked=0 -> has-fee invoice, click to submit payment method and finalize
     * requiresPaymentMethod=1 isBlocked=1 -> has-fee invoice, need to collect payment method but cannot finalize
     */
    interface AggregateInvoiceTypeAndPaymentState {
      /**
       * whether we need to collect payment information, either to collect payment immediately or store for future use
       * (i.e. in the "is blocked" case)
       */
      requiresPaymentMethod: boolean,
      /**
       * whether we can positively attempt to pay/complete this invoice
       */
      isBlocked: boolean,
      entireInvoiceIs: "qEventSignup" | "qCompetitionRegistration" | "qTournamentTeam(teamReg)" | "invoiceTemplateWithNoLineItems"
      // could be separate decomposed flags, but want to handle them all as a single enum
      status: "fee/blocked" | "fee/not-blocked" | "zero-fee/blocked" | "zero-fee/not-blocked"
    }

    const paymentState = computed<AggregateInvoiceTypeAndPaymentState | null>(() => {
      const result = maybeGetCompRegState()
        || maybeGetEventSignupState()
        || maybeGetTournamentTeamRegState()
        || maybeGetInvoiceTemplateBasedPaymentState();

      if (result) {
        return result;
      }
      else {
        // this represents one of a few possible scenarios:
        // - bug on our part (we didn't search properly for the line items?)
        // - the invoice has no associated line items, generally because the invoice was voided (why did we mount `PaymentTools` if that's the case?)
        // - maybe a data race? as in, `props.invoiceInstance` is currently `undefined`, which can happen if one of:
        //   - router.params.invoiceID is invalid, or not yet bound
        //   - request to load invoice has fired but has not yet resolved
        return null;
      }

      function maybeGetInvoiceTemplateBasedPaymentState() : AggregateInvoiceTypeAndPaymentState | null {
        if (!props.invoiceInstance.invoiceID || props.invoiceInstance.lineItems.length !== 1 || props.invoiceInstance.lineItems[0].entity_type !== "invoiceTemplateDummyLine") {
          return null
        }

        const hasFee = true // TODO: handle zero-fee case
        const isBlocked = !!props.invoiceInstance.lineItems[0].paymentBlock_isBlocked // always false for such an invoice, yeah?

        return {
          requiresPaymentMethod: true,
          isBlocked: isBlocked,
          entireInvoiceIs: "invoiceTemplateWithNoLineItems",
          status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
        }
      }

      function maybeGetCompRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const compRegLineItems = maybeGetCompRegLineItems(props.invoiceInstance);
        if (compRegLineItems) {
          assertTruthy(compRegLineItems.length > 0, "if we got an array it has at least one element");
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const isBlocked : boolean = compRegLineItems.some(v => !!v.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qCompetitionRegistration",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetEventSignupState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeEventSignups = maybeGetEventSignups(props.invoiceInstance);
        if (maybeEventSignups) {
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          // we don't currently block eventsignup invoice lineitems, but presumably we could at some point start doing so
          const isBlocked : boolean = !!maybeEventSignups.some(_ => _.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qEventSignup",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetTournamentTeamRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeIsTournamentTeamReg = maybeGetTournamentTeamRegLineItem(props.invoiceInstance);
        if (maybeIsTournamentTeamReg) {
          if (props.tournamentTeamRegHoldPaymentInvoice === null) {
            throw Error("TournamentTeamReg invoice without associated hold payment invoice");
          }

          const hasRegFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const hasHoldPaymentFee = !!parseFloatOr(props.tournamentTeamRegHoldPaymentInvoice.lineItemSum, null)

          // right now it is not expected that a tournteamreg invoice is ever blocked, but let's not hardcode `blocked=false` here
          const isBlocked : boolean = !!maybeIsTournamentTeamReg.paymentBlock_isBlocked;

          return {
            // A tournament team registration requires payment info if it has a reg fee OR a hold payment fee,
            // where the hold payment fee is a separate invoice that may be
            // collected at some point the future, at a registrar's discretion, based on some
            // league based rules.
            requiresPaymentMethod: hasRegFee || hasHoldPaymentFee,
            isBlocked,
            entireInvoiceIs: "qTournamentTeam(teamReg)",
            status: quadStateZeroFeeAndBlocked(hasRegFee, isBlocked)
          }
        }
        return null;
      }

      function quadStateZeroFeeAndBlocked(hasFee: boolean, isBlocked: boolean) : AggregateInvoiceTypeAndPaymentState["status"] {
        if (!hasFee && !isBlocked) {
          return "zero-fee/not-blocked";
        }
        else if (!hasFee && isBlocked) {
          return "zero-fee/blocked"
        }
        else if (hasFee && !isBlocked) {
          return "fee/not-blocked";
        }
        else if (hasFee && isBlocked) {
          return "fee/blocked";
        }
        else {
          throw Error("unreachable");
        }
      }
    })

    const paymentButtonLabel = computed<string>(() => {
      const NO_BUTTON_SO_NO_LABEL = "";
      if (!paymentState.value) {
        return "";
      }

      switch (paymentState.value.entireInvoiceIs) {
        case "qCompetitionRegistration": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              return "Submit payment information";
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              return NO_BUTTON_SO_NO_LABEL;
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qEventSignup": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qTournamentTeam(teamReg)": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return "Submit payment information";
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "invoiceTemplateWithNoLineItems": {
          switch (paymentState.value.status) {
            case "fee/not-blocked":
              return "Submit payment";
            case "fee/blocked":
            case "zero-fee/blocked":
            case "zero-fee/not-blocked":
              unreachable()
            default: exhaustiveCaseGuard(paymentState.value.status)
          }
        }
        default: return exhaustiveCaseGuard(paymentState.value.entireInvoiceIs);
      }
    })

    const nextAction = ref<Stripe.PaymentIntent.NextAction | null>(null)
    watch(() => props.invoiceInstance.lastStatus, async () => {
      if (props.invoiceInstance.lastStatus === LastStatus_t.STRIPE_REQUIRES_ACTION) {
        const pi = await stripe_getPaymentIntentForInvoice(axiosAuthBackgroundInstance, {invoiceInstanceID: props.invoiceInstance.instanceID})
        if (pi.next_action) {
          nextAction.value = pi.next_action
        }
        else {
          nextAction.value = null
        }
      }
      else {
        nextAction.value = null
      }
    }, {immediate: true})

    onMounted(async () => {
      if (Screen.width < 768) {
        exp.value = 'Exp.'
      }

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await hydratePaymentMethods()
        await mountStripe()
      });
    })

    onBeforeUnmount(() => {
      // paranoiac safenav
      card.value?.unmount?.();
    })

    const iziToast = useIziToast()

    const checkoutVia_collectUsBankAccountInfo_manualAcctNumber = async (event: CreateManualAchPaymentMethodEvent) : Promise<void> => {
      if (isSubscriptionInvoice(props.invoiceInstance)) {
        await setupIntentFlow("ACH Debit payment methods requires bank account verification prior to being used for an installment plan.")
      }
      else {
        // well, we could use the paymentIntent flow, but that produces
        // paymentMethods that will not yet be verified, meaning attempting to confirm a paymentIntent
        // with it on the backend will enter it into the "requires_action" state, which we don't currently want to
        // support.
        await setupIntentFlow("ACH Debit payment methods requires bank account verification prior to being used for this invoice.")
      }

      async function setupIntentFlow(onVerifyWithMicrodepositsMsg: string) {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const pm = await makePaymentMethod()

          if (pm.error) {
            iziToast.error({message: pm.error.message, timeout: false})
            return;
          }

          const setupIntent = await stripe_createAchSetupIntent_manualAcctNumber(axiosInstance, {clientGatewayID: props.invoiceInstance.paymentGatewayID, bankAccount_paymentMethodID: pm.paymentMethod.id})

          selectedPaymentMethodID.value = pm.paymentMethod.id

          if (setupIntent.status === "requires_action" && setupIntent.next_action?.type === "verify_with_microdeposits") {
            await hydratePaymentMethods()
            iziToast.warning({message: onVerifyWithMicrodepositsMsg, timeout: 30_000})
            return;
          }
          else {
            // Alot of other error states on the setupIntent are unhandled,
            // but are expected to bubble out of this call with an error about being unable to pay
            await submitPayment({paymentMethodID: pm.paymentMethod.id});
          }
        })
      }

      async function makePaymentMethod() {
        assertNonNull(stripe.value)
        assertNonNull(User.userData)
        return await stripe.value.createPaymentMethod({
            type: "us_bank_account",
            us_bank_account: {
              account_number: event.accountNumber,
              routing_number: event.routingNumber,
              account_holder_type: event.accountHolderType,
            },
            billing_details: {
              email: User.userData?.email,
              name: `${User.userData?.firstName} ${User.userData?.lastName}`
            },
          });
      }
    }

    const checkoutVia_collectUsBankAccountInfo_stripeModal = async () : Promise<void> => {
      // there's also a paymentIntent flow we might want to enable at some point,
      // but because we don't use it, in order to avoid allowing paymentIntents entering the "requires_action=verify_with_microdeposits"
      // state.
      await subscription_stripeModal_setupIntentFlow()

      async function subscription_stripeModal_setupIntentFlow() {
        if (!stripe.value) {
          return
        }

        const clientGatewayID = props.invoiceInstance.paymentGatewayID
        const setupIntent = await stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal(axiosInstance, {clientGatewayID});

        if (!setupIntent.client_secret) {
          iziToast.error({message: FALLBACK_ERROR_MESSAGE})
          // don't expect this to happen --- when does a setup_intent not have a client secret?
          maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/no-client-secret", {setupIntent: setupIntent.id, invoiceInstanceID: props.invoiceInstance.instanceID})
          return;
        }

        const clientSecret = setupIntent.client_secret

        assertNonNull(User.userData)

        const result = await stripe.value.collectBankAccountForSetup({
          clientSecret,
          params: {
            payment_method_type: "us_bank_account",
            payment_method_data: {
              billing_details: {
                name: `${User.userData.firstName} ${User.userData.lastName}`,
                email: User.userData.email,
              }
            },
          },
          expand: ["payment_method"],
        })

        if (result.error) {
          iziToast.error({message: result.error.message, timeout: 60 * 1000})
          return;
        }

        if (result.setupIntent.status === "requires_payment_method") {
          // canceled by user, flow incomplete, bail. We'll be left with an abandoned SetupIntent. Oh well, no big deal.
          return
        }
        else if (result.setupIntent.status === "requires_confirmation") {
          if (result.setupIntent.client_secret && typeof result.setupIntent.payment_method === "object" && result.setupIntent.payment_method?.us_bank_account) {
            const secret = result.setupIntent.client_secret
            stripeModal_afterStripeModal_inleagueAchMandateModalController.open({
              bankAccount: result.setupIntent.payment_method.us_bank_account,
              onMandateAccepted: async () => {
                GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                  stripeModal_afterStripeModal_inleagueAchMandateModalController.close()
                  await confirmBankAccountForSetupIntent(secret)
                })
              }
            })
          }
          else {
            // shouldn't happen, but nothing we can do here
            maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/ach/setupIntent/unexpected-state-1", result)
            return
          }
        }
        else {
          maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/ach/setupIntent/unexpected-state-2", result)
          return
        }

        async function confirmBankAccountForSetupIntent(setupIntent_clientSecret: string) {
          // this means "we've offered them the mandate, and they confirmed it. Finish setting up the setup intent."
          const result = await stripe.value!.confirmUsBankAccountSetup(setupIntent_clientSecret)

          if (result.error) {
            iziToast.error({message: result.error.message, timeout: false})
            maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/mandate-modal/setup-intent", result)
            return
          }
          else {
            const paymentMethodID = stripeObjId(result.setupIntent.payment_method)
            if (!paymentMethodID) {
              // shouldn't happen
              return;
            }

            await stripe_trackMultiUseActiveMandate(axiosInstance, {clientGatewayID: props.invoiceInstance.paymentGatewayID, setupIntentID: setupIntent.id})

            if (result.setupIntent.status === "succeeded") {
              selectedPaymentMethodID.value = paymentMethodID
              await submitPayment()
            }
            else {
              await hydratePaymentMethods();
              // no reason it wouldn't be in the new list, but we'll make sure it is before
              if (paymentMethods.value.find(v => v.id === paymentMethodID && v)) {
                selectedPaymentMethodID.value = paymentMethodID
              }
            }
          }
        }
      }
    }

    /**
     * A modal we show _after_ the stripe "choose your bank account" modal,
     * to display our mandate. If this modal is dismissed, we do not proceed
     * with setupIntent confirmation or attempt any payment.
     *
     * In the non-stripeModal case (where we offer our own form that collects routing/account number),
     * our mandate is inline with the form, and this modal is not necessary.
     */
    const stripeModal_afterStripeModal_inleagueAchMandateModalController = (() => {
      return DefaultModalController_r<
        {
          // callback is responsible for closing the modal in the "on accepted" case
          onMandateAccepted: () => Promise<void>,
          bankAccount: PaymentMethod.UsBankAccount
        }
      >({
        title: () => <>
          <div>Direct Debit Authorization</div>
          <div class="my-2 border-b"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }

          return <AchMandateModal
            bankAccount={data.bankAccount}
            merchantOfRecord={merchantOfRecord.value}
            onOk={async () => {
              await data.onMandateAccepted()
            }}
            onCancel={() => stripeModal_afterStripeModal_inleagueAchMandateModalController.close()}
          />
        }
      })
    })()

    return {
      getCardPaymentMethodAndSubmitPayment,
      deletePaymentMethod,
      toggleCardSelected,
      showPaymentRequest,
      mountStripe,
      submitPayment,
      router,
      route,
      AppleOrGooglePay,
      exp,
      msg,
      event,
      enrolled,
      error,
      checkoutVia_collectUsBankAccountInfo_manualAcctNumber,
      checkoutVia_collectUsBankAccountInfo_stripeModal,
      paymentInProgress,
      savePaymentMethod,
      selectedPaymentMethodID: selectedPaymentMethodID,
      // need to not ref-unwrap in some cases
      selectedPaymentMethodID_reflike: {
        get value() { return selectedPaymentMethodID.value },
        set value(v) { selectedPaymentMethodID.value = v },
      },
      stripeAccount,
      paymentMethods,
      stripe,
      card,
      spk,
      achEnabled,
      payByCashOrCheck,
      hasSomePaymentBlockedLineItem,
      paymentState,
      paymentButtonLabel,
      stripeCardElementReady,
      achMandateModalController: stripeModal_afterStripeModal_inleagueAchMandateModalController,
      collectPaymentMethodDetailsType,
      CollectPaymentMethodDetailsType,
      manuallyEnterAchDetails,
      merchantOfRecord,
      nextAction,
      selectedPaymentMethodUnusable,
      allowedPaymentMethods,
    }
  },
})
</script>

<style scoped>
/* Variables */
* {
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}
form.xform-1 {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
input {
  border: 1px solid rgba(50, 50, 93, 0.1);
  font-size: 16px;
  background: white;
}
.result-message {
  line-height: 22px;
  font-size: 16px;
}
.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.hidden {
  display: none;
}
#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}
#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}
#payment-request-button {
  margin-bottom: 32px;
}
</style>
